import Header from "../components/Header";
import AboutContent from "../components/AboutContent";


const About = ()=>{

    return (<div>

        <Header/>
        <AboutContent/>

    </div>)


}


export default About;