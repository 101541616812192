

const suwar = [
      {
        "id": 1,
        "name": "الفاتحة",
        "start_page": 1,
        "end_page": 1,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 2,
        "name": "البقرة",
        "start_page": 2,
        "end_page": 49,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 3,
        "name": "آل عمران",
        "start_page": 50,
        "end_page": 76,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 4,
        "name": "النساء",
        "start_page": 77,
        "end_page": 106,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 5,
        "name": "المائدة",
        "start_page": 106,
        "end_page": 127,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 6,
        "name": "الأنعام",
        "start_page": 128,
        "end_page": 150,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 7,
        "name": "الأعراف",
        "start_page": 151,
        "end_page": 176,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 8,
        "name": "الأنفال",
        "start_page": 177,
        "end_page": 186,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 9,
        "name": "التوبة",
        "start_page": 187,
        "end_page": 207,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 10,
        "name": "يونس",
        "start_page": 208,
        "end_page": 221,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 11,
        "name": "هود",
        "start_page": 221,
        "end_page": 235,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 12,
        "name": "يوسف",
        "start_page": 235,
        "end_page": 248,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 13,
        "name": "الرعد",
        "start_page": 249,
        "end_page": 255,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 14,
        "name": "إبراهيم",
        "start_page": 255,
        "end_page": 261,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 15,
        "name": "الحجر",
        "start_page": 262,
        "end_page": 267,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 16,
        "name": "النحل",
        "start_page": 267,
        "end_page": 281,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 17,
        "name": "الإسراء",
        "start_page": 282,
        "end_page": 293,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 18,
        "name": "الكهف",
        "start_page": 293,
        "end_page": 304,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 19,
        "name": "مريم",
        "start_page": 305,
        "end_page": 312,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 20,
        "name": "طه",
        "start_page": 312,
        "end_page": 321,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 21,
        "name": "الأنبياء",
        "start_page": 322,
        "end_page": 331,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 22,
        "name": "الحج",
        "start_page": 332,
        "end_page": 341,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 23,
        "name": "المؤمنون",
        "start_page": 342,
        "end_page": 349,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 24,
        "name": "النور",
        "start_page": 350,
        "end_page": 359,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 25,
        "name": "الفرقان",
        "start_page": 359,
        "end_page": 366,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 26,
        "name": "الشعراء",
        "start_page": 367,
        "end_page": 376,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 27,
        "name": "النمل",
        "start_page": 377,
        "end_page": 385,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 28,
        "name": "القصص",
        "start_page": 385,
        "end_page": 396,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 29,
        "name": "العنكبوت",
        "start_page": 396,
        "end_page": 404,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 30,
        "name": "الروم",
        "start_page": 404,
        "end_page": 410,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 31,
        "name": "لقمان",
        "start_page": 411,
        "end_page": 414,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 32,
        "name": "السجدة",
        "start_page": 415,
        "end_page": 417,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 33,
        "name": "الأحزاب",
        "start_page": 418,
        "end_page": 427,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 34,
        "name": "سبأ",
        "start_page": 428,
        "end_page": 434,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 35,
        "name": "فاطر",
        "start_page": 434,
        "end_page": 440,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 36,
        "name": "يس",
        "start_page": 440,
        "end_page": 445,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 37,
        "name": "الصافات",
        "start_page": 446,
        "end_page": 452,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 38,
        "name": "ص",
        "start_page": 453,
        "end_page": 458,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 39,
        "name": "الزمر",
        "start_page": 458,
        "end_page": 467,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 40,
        "name": "غافر",
        "start_page": 467,
        "end_page": 476,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 41,
        "name": "فصلت",
        "start_page": 477,
        "end_page": 482,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 42,
        "name": "الشورى",
        "start_page": 483,
        "end_page": 489,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 43,
        "name": "الزخرف",
        "start_page": 489,
        "end_page": 495,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 44,
        "name": "الدّخان",
        "start_page": 496,
        "end_page": 498,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 45,
        "name": "الجاثية",
        "start_page": 499,
        "end_page": 502,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 46,
        "name": "الأحقاف",
        "start_page": 502,
        "end_page": 506,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 47,
        "name": "محمد",
        "start_page": 507,
        "end_page": 510,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 48,
        "name": "الفتح",
        "start_page": 511,
        "end_page": 515,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 49,
        "name": "الحجرات",
        "start_page": 515,
        "end_page": 517,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 50,
        "name": "ق",
        "start_page": 518,
        "end_page": 520,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 51,
        "name": "الذاريات",
        "start_page": 520,
        "end_page": 523,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 52,
        "name": "الطور",
        "start_page": 523,
        "end_page": 525,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 53,
        "name": "النجم",
        "start_page": 526,
        "end_page": 528,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 54,
        "name": "القمر",
        "start_page": 528,
        "end_page": 531,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 55,
        "name": "الرحمن",
        "start_page": 531,
        "end_page": 534,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 56,
        "name": "الواقعة",
        "start_page": 534,
        "end_page": 537,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 57,
        "name": "الحديد",
        "start_page": 537,
        "end_page": 541,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 58,
        "name": "المجادلة",
        "start_page": 542,
        "end_page": 545,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 59,
        "name": "الحشر",
        "start_page": 545,
        "end_page": 548,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 60,
        "name": "الممتحنة",
        "start_page": 549,
        "end_page": 551,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 61,
        "name": "الصف",
        "start_page": 551,
        "end_page": 552,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 62,
        "name": "الجمعة",
        "start_page": 553,
        "end_page": 554,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 63,
        "name": "المنافقون",
        "start_page": 554,
        "end_page": 555,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 64,
        "name": "التغابن",
        "start_page": 556,
        "end_page": 557,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 65,
        "name": "الطلاق",
        "start_page": 558,
        "end_page": 559,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 66,
        "name": "التحريم",
        "start_page": 560,
        "end_page": 561,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 67,
        "name": "الملك",
        "start_page": 562,
        "end_page": 564,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 68,
        "name": "القلم",
        "start_page": 564,
        "end_page": 566,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 69,
        "name": "الحاقة",
        "start_page": 566,
        "end_page": 568,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 70,
        "name": "المعارج",
        "start_page": 568,
        "end_page": 570,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 71,
        "name": "نوح",
        "start_page": 570,
        "end_page": 571,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 72,
        "name": "الجن",
        "start_page": 572,
        "end_page": 573,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 73,
        "name": "المزمل",
        "start_page": 574,
        "end_page": 575,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 74,
        "name": "المدثر",
        "start_page": 575,
        "end_page": 577,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 75,
        "name": "القيامة",
        "start_page": 577,
        "end_page": 578,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 76,
        "name": "الإنسان",
        "start_page": 578,
        "end_page": 580,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 77,
        "name": "المرسلات",
        "start_page": 580,
        "end_page": 581,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 78,
        "name": "النبأ",
        "start_page": 582,
        "end_page": 583,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 79,
        "name": "النازعات",
        "start_page": 583,
        "end_page": 584,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 80,
        "name": "عبس",
        "start_page": 585,
        "end_page": 586,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 81,
        "name": "التكوير",
        "start_page": 586,
        "end_page": 586,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 82,
        "name": "الإنفطار",
        "start_page": 587,
        "end_page": 587,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 83,
        "name": "المطففين",
        "start_page": 587,
        "end_page": 589,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 84,
        "name": "الإنشقاق",
        "start_page": 589,
        "end_page": 590,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 85,
        "name": "البروج",
        "start_page": 590,
        "end_page": 590,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 86,
        "name": "الطارق",
        "start_page": 591,
        "end_page": 591,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 87,
        "name": "الأعلى",
        "start_page": 591,
        "end_page": 592,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 88,
        "name": "الغاشية",
        "start_page": 592,
        "end_page": 593,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 89,
        "name": "الفجر",
        "start_page": 593,
        "end_page": 594,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 90,
        "name": "البلد",
        "start_page": 594,
        "end_page": 595,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 91,
        "name": "الشمس",
        "start_page": 595,
        "end_page": 595,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 92,
        "name": "الليل",
        "start_page": 595,
        "end_page": 596,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 93,
        "name": "الضحى",
        "start_page": 596,
        "end_page": 596,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 94,
        "name": "الشرح",
        "start_page": 596,
        "end_page": 597,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 95,
        "name": "التين",
        "start_page": 597,
        "end_page": 597,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 96,
        "name": "العلق",
        "start_page": 597,
        "end_page": 598,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 97,
        "name": "القدر",
        "start_page": 598,
        "end_page": 598,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 98,
        "name": "البينة",
        "start_page": 598,
        "end_page": 599,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 99,
        "name": "الزلزلة",
        "start_page": 599,
        "end_page": 599,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 100,
        "name": "العاديات",
        "start_page": 599,
        "end_page": 600,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 101,
        "name": "القارعة",
        "start_page": 600,
        "end_page": 600,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 102,
        "name": "التكاثر",
        "start_page": 600,
        "end_page": 600,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 103,
        "name": "العصر",
        "start_page": 601,
        "end_page": 601,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 104,
        "name": "الهمزة",
        "start_page": 601,
        "end_page": 601,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 105,
        "name": "الفيل",
        "start_page": 601,
        "end_page": 601,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 106,
        "name": "قريش",
        "start_page": 602,
        "end_page": 602,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 107,
        "name": "الماعون",
        "start_page": 602,
        "end_page": 602,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 108,
        "name": "الكوثر",
        "start_page": 602,
        "end_page": 602,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 109,
        "name": "الكافرون",
        "start_page": 603,
        "end_page": 603,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 110,
        "name": "النصر",
        "start_page": 603,
        "end_page": 603,
        "makkia": 0,
        "type": 1
      },
      {
        "id": 111,
        "name": "المسد",
        "start_page": 603,
        "end_page": 603,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 112,
        "name": "الإخلاص",
        "start_page": 604,
        "end_page": 604,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 113,
        "name": "الفلق",
        "start_page": 604,
        "end_page": 604,
        "makkia": 1,
        "type": 0
      },
      {
        "id": 114,
        "name": "الناس",
        "start_page": 604,
        "end_page": 604,
        "makkia": 1,
        "type": 0
      }
    ];


module.exports = suwar;    