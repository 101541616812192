function handleReciters(reciters){

  reciters.sort(function(a, b){if(a.name < b.name) { return -1; }
  if(a.name > b.name) { return 1; }
  return 0;});


  let temp = [];

  reciters.forEach(function(item) {
  
  item.moshaf.forEach(function(item2) {
    if(item2.surah_total===114) {
      temp.push(item2);
    }
  });
  
  item.moshaf=temp;
  temp=[];
});

reciters.forEach(function(item) {

  if(item.moshaf.length>0)temp.push(item);

});

while (reciters.length > 0) {
  reciters.pop();
}
temp.forEach(e=>{reciters.push(e);}
)
  
}

const  reciters = [
      {
        "id": 1,
        "name": "إبراهيم الأخضر",
        "letter": "إ",
        "moshaf": [
          {
            "id": 1,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/akdr/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 10,
        "name": "أكرم العلاقمي",
        "letter": "أ",
        "moshaf": [
          {
            "id": 10,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/akrm/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 100,
        "name": "محمد صالح عالم شاه",
        "letter": "م",
        "moshaf": [
          {
            "id": 105,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/shah/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 101,
        "name": "محمد جبريل",
        "letter": "م",
        "moshaf": [
          {
            "id": 106,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/jbrl/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 102,
        "name": "محمد صديق المنشاوي",
        "letter": "م",
        "moshaf": [
          {
            "id": 109,
            "name": "المصحف المعلم - المصحف المعلم",
            "server": "https://server10.mp3quran.net/minsh/Almusshaf-Al-Mo-lim/",
            "surah_total": 114,
            "moshaf_type": 418,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 108,
            "name": "المصحف المجود - المصحف المجود",
            "server": "https://server10.mp3quran.net/minsh/Almusshaf-Al-Mojawwad/",
            "surah_total": 114,
            "moshaf_type": 617,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 107,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/minsh/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 103,
        "name": "محمد عبدالكريم",
        "letter": "م",
        "moshaf": [
          {
            "id": 171,
            "name": "ورش عن نافع من طريق أبي بكر الأصبهاني - مرتل",
            "server": "https://server12.mp3quran.net/m_krm/Rewayat-Warsh-A-n-Nafi-Men-Tariq-Abi-Baker-Alasbahani/",
            "surah_total": 114,
            "moshaf_type": 1016,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 110,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/m_krm/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 104,
        "name": "محمد عبدالحكيم سعيد العبدالله",
        "letter": "م",
        "moshaf": [
          {
            "id": 112,
            "name": "الدوري عن الكسائي - مرتل",
            "server": "https://server9.mp3quran.net/abdullah/Rewayat-AlDorai-A-n-Al-Kisa-ai/",
            "surah_total": 114,
            "moshaf_type": 1216,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 111,
            "name": "البزي وقنبل عن ابن كثير - مرتل",
            "server": "https://server9.mp3quran.net/abdullah/",
            "surah_total": 114,
            "moshaf_type": 1116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 105,
        "name": "محمود خليل الحصري",
        "letter": "م",
        "moshaf": [
          {
            "id": 224,
            "name": "قالون عن نافع - مرتل",
            "server": "https://server13.mp3quran.net/husr/Rewayat-Qalon-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 516,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 223,
            "name": "الدوري عن أبي عمرو - مرتل",
            "server": "https://server13.mp3quran.net/husr/Rewayat-Aldori-A-n-Abi-Amr/",
            "surah_total": 114,
            "moshaf_type": 1316,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 115,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server13.mp3quran.net/husr/Rewayat-Warsh-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 216,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 114,
            "name": "المصحف المجود - المصحف المجود",
            "server": "https://server13.mp3quran.net/husr/Almusshaf-Al-Mojawwad/",
            "surah_total": 114,
            "moshaf_type": 617,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 113,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server13.mp3quran.net/husr/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 106,
        "name": "محمود علي البنا",
        "letter": "م",
        "moshaf": [
          {
            "id": 117,
            "name": "المصحف المجود - المصحف المجود",
            "server": "https://server8.mp3quran.net/bna/Almusshaf-Al-Mojawwad/",
            "surah_total": 114,
            "moshaf_type": 617,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 116,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/bna/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 107,
        "name": "مشاري العفاسي",
        "letter": "م",
        "moshaf": [
          {
            "id": 119,
            "name": "الدوري عن الكسائي - مرتل",
            "server": "https://server8.mp3quran.net/afs/Rewayat-AlDorai-A-n-Al-Kisa-ai/",
            "surah_total": 5,
            "moshaf_type": 1216,
            "surah_list": "14,25,87,97,99"
          },
          {
            "id": 118,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/afs/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 108,
        "name": "مصطفى إسماعيل",
        "letter": "م",
        "moshaf": [
          {
            "id": 272,
            "name": "المصحف المجود - المصحف المجود",
            "server": "https://server8.mp3quran.net/mustafa/Almusshaf-Al-Mojawwad/",
            "surah_total": 114,
            "moshaf_type": 617,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 120,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/mustafa/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 109,
        "name": "مصطفى اللاهوني",
        "letter": "م",
        "moshaf": [
          {
            "id": 121,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/lahoni/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 11,
        "name": "الحسيني العزازي",
        "letter": "ا",
        "moshaf": [
          {
            "id": 11,
            "name": "المصحف المعلم - المصحف المعلم",
            "server": "https://server8.mp3quran.net/3zazi/",
            "surah_total": 57,
            "moshaf_type": 418,
            "surah_list": "58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 110,
        "name": "مصطفى رعد العزاوي",
        "letter": "م",
        "moshaf": [
          {
            "id": 122,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/ra3ad/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 111,
        "name": "معمر الأندونيسي",
        "letter": "م",
        "moshaf": [
          {
            "id": 123,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/muamr/",
            "surah_total": 8,
            "moshaf_type": 116,
            "surah_list": "36,93,94,97,101,109,110,111"
          }
        ]
      },
      {
        "id": 113,
        "name": "ماجد الزامل",
        "letter": "م",
        "moshaf": [
          {
            "id": 125,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/zaml/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 114,
        "name": "ماهر شخاشيرو",
        "letter": "م",
        "moshaf": [
          {
            "id": 126,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/shaksh/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 115,
        "name": "محمد المنشد",
        "letter": "م",
        "moshaf": [
          {
            "id": 127,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/monshed/",
            "surah_total": 110,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,80,81,82,83,84,85,86,87,88,90,91,92,93,94,95,96,97,98,99,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 116,
        "name": "محمود الشيمي",
        "letter": "م",
        "moshaf": [
          {
            "id": 128,
            "name": "الدوري عن الكسائي - مرتل",
            "server": "https://server10.mp3quran.net/sheimy/",
            "surah_total": 114,
            "moshaf_type": 1216,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 117,
        "name": "ياسر سلامة",
        "letter": "ي",
        "moshaf": [
          {
            "id": 129,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/salamah/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 118,
        "name": "أخيل عبدالحي روا",
        "letter": "أ",
        "moshaf": [
          {
            "id": 130,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/malaysia/akil/",
            "surah_total": 4,
            "moshaf_type": 116,
            "surah_list": "50,51,52,56"
          }
        ]
      },
      {
        "id": 119,
        "name": "أستاذ زامري",
        "letter": "أ",
        "moshaf": [
          {
            "id": 131,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/malaysia/zamri/",
            "surah_total": 7,
            "moshaf_type": 116,
            "surah_list": "32,44,55,56,61,67,76"
          }
        ]
      },
      {
        "id": 12,
        "name": "إدريس أبكر",
        "letter": "إ",
        "moshaf": [
          {
            "id": 12,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/abkr/",
            "surah_total": 111,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,47,48,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 120,
        "name": "خالد المهنا",
        "letter": "خ",
        "moshaf": [
          {
            "id": 132,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/mohna/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 121,
        "name": "عادل الكلباني",
        "letter": "ع",
        "moshaf": [
          {
            "id": 133,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/a_klb/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 122,
        "name": "موسى بلال",
        "letter": "م",
        "moshaf": [
          {
            "id": 134,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/bilal/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 123,
        "name": "حسين آل الشيخ",
        "letter": "ح",
        "moshaf": [
          {
            "id": 135,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/alshaik/",
            "surah_total": 15,
            "moshaf_type": 116,
            "surah_list": "13,14,22,32,38,44,45,49,50,78,79,80,81,82,85"
          }
        ]
      },
      {
        "id": 124,
        "name": "حاتم فريد الواعر",
        "letter": "ح",
        "moshaf": [
          {
            "id": 136,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/hatem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 125,
        "name": "إبراهيم الجرمي",
        "letter": "إ",
        "moshaf": [
          {
            "id": 137,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/jormy/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 126,
        "name": "محمود الرفاعي",
        "letter": "م",
        "moshaf": [
          {
            "id": 138,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/mrifai/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 127,
        "name": "ناصر العبيد",
        "letter": "ن",
        "moshaf": [
          {
            "id": 139,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/obaid/",
            "surah_total": 9,
            "moshaf_type": 116,
            "surah_list": "7,13,14,15,25,26,27,40,41"
          }
        ]
      },
      {
        "id": 128,
        "name": "واصل المذن",
        "letter": "و",
        "moshaf": [
          {
            "id": 140,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/wasel/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 9,
            "moshaf_type": 116,
            "surah_list": "8,9,36,38,42,45,50,59,60"
          }
        ]
      },
      {
        "id": 129,
        "name": "إبراهيم الدوسري",
        "letter": "إ",
        "moshaf": [
          {
            "id": 188,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/ibrahim_dosri/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 142,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server10.mp3quran.net/ibrahim_dosri/Rewayat-Warsh-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 216,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 13,
        "name": "الزين محمد أحمد",
        "letter": "ا",
        "moshaf": [
          {
            "id": 13,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/alzain/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 130,
        "name": "جمعان العصيمي",
        "letter": "ج",
        "moshaf": [
          {
            "id": 144,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/jaman/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 131,
        "name": "رضية عبدالرحمن",
        "letter": "ر",
        "moshaf": [
          {
            "id": 146,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/malaysia/rziah/",
            "surah_total": 4,
            "moshaf_type": 116,
            "surah_list": "3,8,33,35"
          }
        ]
      },
      {
        "id": 132,
        "name": "رقية سولونق",
        "letter": "ر",
        "moshaf": [
          {
            "id": 147,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/malaysia/rogiah/",
            "surah_total": 1,
            "moshaf_type": 116,
            "surah_list": "36"
          }
        ]
      },
      {
        "id": 133,
        "name": "سابينة مامات",
        "letter": "س",
        "moshaf": [
          {
            "id": 148,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/malaysia/mamat/",
            "surah_total": 4,
            "moshaf_type": 116,
            "surah_list": "3,14,21,22"
          }
        ]
      },
      {
        "id": 134,
        "name": "سيدين عبدالرحمن",
        "letter": "س",
        "moshaf": [
          {
            "id": 149,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/malaysia/sideen/",
            "surah_total": 4,
            "moshaf_type": 116,
            "surah_list": "17,23,56,75"
          }
        ]
      },
      {
        "id": 135,
        "name": "عبدالغني عبدالله",
        "letter": "ع",
        "moshaf": [
          {
            "id": 150,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/malaysia/abdulgani/",
            "surah_total": 13,
            "moshaf_type": 116,
            "surah_list": "1,2,5,6,9,67,87,91,92,94,95,97,114"
          }
        ]
      },
      {
        "id": 136,
        "name": "عبدالله فهمي",
        "letter": "ع",
        "moshaf": [
          {
            "id": 151,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/malaysia/fhmi/",
            "surah_total": 4,
            "moshaf_type": 116,
            "surah_list": "1,36,53,75"
          }
        ]
      },
      {
        "id": 137,
        "name": "محمد الحافظ",
        "letter": "م",
        "moshaf": [
          {
            "id": 152,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/malaysia/hafz/",
            "surah_total": 3,
            "moshaf_type": 116,
            "surah_list": "1,19,31"
          }
        ]
      },
      {
        "id": 138,
        "name": "محمد حفص علي",
        "letter": "م",
        "moshaf": [
          {
            "id": 153,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/malaysia/hfs/",
            "surah_total": 5,
            "moshaf_type": 116,
            "surah_list": "1,9,11,13,67"
          }
        ]
      },
      {
        "id": 139,
        "name": "محمد خير النور",
        "letter": "م",
        "moshaf": [
          {
            "id": 154,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/malaysia/nor/",
            "surah_total": 4,
            "moshaf_type": 116,
            "surah_list": "1,12,19,31"
          }
        ]
      },
      {
        "id": 14,
        "name": "القارئ ياسين",
        "letter": "ا",
        "moshaf": [
          {
            "id": 14,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server11.mp3quran.net/qari/",
            "surah_total": 114,
            "moshaf_type": 216,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 140,
        "name": "يوسف بن نوح أحمد",
        "letter": "ي",
        "moshaf": [
          {
            "id": 155,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/noah/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 141,
        "name": "جمال الدين الزيلعي",
        "letter": "ج",
        "moshaf": [
          {
            "id": 156,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/zilaie/",
            "surah_total": 8,
            "moshaf_type": 116,
            "surah_list": "1,19,32,54,67,70,73,91"
          }
        ]
      },
      {
        "id": 142,
        "name": "معيض الحارثي",
        "letter": "م",
        "moshaf": [
          {
            "id": 159,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/harthi/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 143,
        "name": "محمد رشاد الشريف",
        "letter": "م",
        "moshaf": [
          {
            "id": 160,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/rashad/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 145,
        "name": "أحمد الطرابلسي",
        "letter": "أ",
        "moshaf": [
          {
            "id": 215,
            "name": "قالون عن نافع - مرتل",
            "server": "https://server10.mp3quran.net/trablsi/",
            "surah_total": 114,
            "moshaf_type": 516,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 162,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/trabulsi/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 146,
        "name": "عبدالله الكندري",
        "letter": "ع",
        "moshaf": [
          {
            "id": 163,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/Abdullahk/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 147,
        "name": "أحمد عامر",
        "letter": "أ",
        "moshaf": [
          {
            "id": 164,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/Aamer/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 148,
        "name": "إبراهيم السعدان",
        "letter": "إ",
        "moshaf": [
          {
            "id": 165,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/IbrahemSadan/",
            "surah_total": 4,
            "moshaf_type": 116,
            "surah_list": "1,6,19,20"
          }
        ]
      },
      {
        "id": 149,
        "name": "أحمد الحذيفي",
        "letter": "أ",
        "moshaf": [
          {
            "id": 166,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/ahmad_huth/",
            "surah_total": 105,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,35,38,41,42,43,44,45,47,48,49,50,51,52,53,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 15,
        "name": "العشري عمران",
        "letter": "ا",
        "moshaf": [
          {
            "id": 15,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/omran/",
            "surah_total": 113,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 150,
        "name": "محمد عثمان خان",
        "letter": "م",
        "moshaf": [
          {
            "id": 167,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/khan/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 151,
        "name": "يوسف الدغوش",
        "letter": "ي",
        "moshaf": [
          {
            "id": 168,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server7.mp3quran.net/dgsh/",
            "surah_total": 22,
            "moshaf_type": 116,
            "surah_list": "1,3,55,67,71,75,82,85,90,91,92,100,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 152,
        "name": "الدوكالي محمد العالم",
        "letter": "ا",
        "moshaf": [
          {
            "id": 169,
            "name": "قالون عن نافع - مرتل",
            "server": "https://server7.mp3quran.net/dokali/",
            "surah_total": 114,
            "moshaf_type": 516,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 153,
        "name": "وشيار حيدر اربيلي",
        "letter": "و",
        "moshaf": [
          {
            "id": 170,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/wishear/",
            "surah_total": 2,
            "moshaf_type": 116,
            "surah_list": "55,56"
          }
        ]
      },
      {
        "id": 154,
        "name": "الفاتح محمد الزبير",
        "letter": "ا",
        "moshaf": [
          {
            "id": 172,
            "name": "الدوري عن أبي عمرو - مرتل",
            "server": "https://server6.mp3quran.net/fateh/",
            "surah_total": 114,
            "moshaf_type": 1316,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 155,
        "name": "طارق عبدالغني دعوب",
        "letter": "ط",
        "moshaf": [
          {
            "id": 173,
            "name": "قالون عن نافع - مرتل",
            "server": "https://server10.mp3quran.net/tareq/",
            "surah_total": 114,
            "moshaf_type": 516,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 156,
        "name": "عثمان الأنصاري",
        "letter": "ع",
        "moshaf": [
          {
            "id": 174,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/Othmn/",
            "surah_total": 76,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,40,41,42,43,44,46,47,48,49,50,51,52,53,54,55,56,58,59,60,61,62,63,64,65,66,67,68,69,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 157,
        "name": "بندر بليله",
        "letter": "ب",
        "moshaf": [
          {
            "id": 175,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/balilah/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 158,
        "name": "خالد الشريمي",
        "letter": "خ",
        "moshaf": [
          {
            "id": 176,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/shoraimy/",
            "surah_total": 73,
            "moshaf_type": 116,
            "surah_list": "1,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 159,
        "name": "وديع اليمني",
        "letter": "و",
        "moshaf": [
          {
            "id": 177,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/wdee3/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 16,
        "name": "العيون الكوشي",
        "letter": "ا",
        "moshaf": [
          {
            "id": 16,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server11.mp3quran.net/koshi/",
            "surah_total": 114,
            "moshaf_type": 216,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 160,
        "name": "رعد محمد الكردي",
        "letter": "ر",
        "moshaf": [
          {
            "id": 178,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/kurdi/",
            "surah_total": 93,
            "moshaf_type": 116,
            "surah_list": "1,2,3,12,13,17,18,19,20,21,22,23,25,26,27,28,29,30,31,32,33,35,36,37,38,39,41,42,43,44,46,47,48,51,52,56,57,58,59,60,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 162,
        "name": "عبدالرحمن العوسي",
        "letter": "ع",
        "moshaf": [
          {
            "id": 181,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/aloosi/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 163,
        "name": "خالد الغامدي",
        "letter": "خ",
        "moshaf": [
          {
            "id": 182,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/ghamdi/",
            "surah_total": 28,
            "moshaf_type": 116,
            "surah_list": "1,6,9,14,21,25,30,42,50,52,53,54,58,59,60,61,65,67,68,69,70,71,77,85,86,88,91,93"
          }
        ]
      },
      {
        "id": 164,
        "name": "رمضان شكور",
        "letter": "ر",
        "moshaf": [
          {
            "id": 183,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/shakoor/",
            "surah_total": 61,
            "moshaf_type": 116,
            "surah_list": "1,3,10,13,14,23,26,29,35,36,39,40,42,43,47,48,49,50,51,57,58,59,60,63,68,69,70,71,72,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,111,112,113,114"
          }
        ]
      },
      {
        "id": 165,
        "name": "عبدالمجيد الأركاني",
        "letter": "ع",
        "moshaf": [
          {
            "id": 184,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server7.mp3quran.net/m_arkani/",
            "surah_total": 46,
            "moshaf_type": 116,
            "surah_list": "12,18,19,21,22,40,50,56,76,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 166,
        "name": "محمد خليل القارئ",
        "letter": "م",
        "moshaf": [
          {
            "id": 185,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/m_qari/",
            "surah_total": 15,
            "moshaf_type": 116,
            "surah_list": "1,13,14,19,22,32,42,52,53,54,67,68,69,70,71"
          }
        ]
      },
      {
        "id": 167,
        "name": "رامي الدعيس",
        "letter": "ر",
        "moshaf": [
          {
            "id": 186,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/rami/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 168,
        "name": "هزاع البلوشي",
        "letter": "ه",
        "moshaf": [
          {
            "id": 187,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/hazza/",
            "surah_total": 75,
            "moshaf_type": 116,
            "surah_list": "1,13,14,15,18,19,25,29,30,31,36,37,38,39,40,42,44,47,49,50,51,52,53,54,55,56,57,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 169,
        "name": "عبدالرحمن الماجد",
        "letter": "ع",
        "moshaf": [
          {
            "id": 189,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/a_majed/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 17,
        "name": "توفيق الصايغ",
        "letter": "ت",
        "moshaf": [
          {
            "id": 17,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/twfeeq/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 170,
        "name": "سلمان العتيبي",
        "letter": "س",
        "moshaf": [
          {
            "id": 190,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/salman/",
            "surah_total": 61,
            "moshaf_type": 116,
            "surah_list": "1,2,36,46,56,58,59,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 171,
        "name": "محمد رفعت",
        "letter": "م",
        "moshaf": [
          {
            "id": 191,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server14.mp3quran.net/refat/",
            "surah_total": 32,
            "moshaf_type": 116,
            "surah_list": "1,2,10,11,12,17,18,19,20,48,54,55,56,69,72,73,75,76,77,78,79,81,82,83,85,86,87,88,89,96,98,100"
          }
        ]
      },
      {
        "id": 172,
        "name": "عبدالله الموسى",
        "letter": "ع",
        "moshaf": [
          {
            "id": 270,
            "name": "المصحف المعلم - المصحف المعلم",
            "server": "https://server14.mp3quran.net/mousa/Almusshaf-Al-Mo-lim/",
            "surah_total": 38,
            "moshaf_type": 418,
            "surah_list": "1,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 192,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server14.mp3quran.net/mousa/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 102,
            "moshaf_type": 116,
            "surah_list": "1,2,3,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,25,26,27,29,31,32,33,35,36,37,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 173,
        "name": "عبدالله الخلف",
        "letter": "ع",
        "moshaf": [
          {
            "id": 193,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server14.mp3quran.net/khalf/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 174,
        "name": "منصور السالمي",
        "letter": "م",
        "moshaf": [
          {
            "id": 194,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server14.mp3quran.net/mansor/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 175,
        "name": "صلاح مصلي",
        "letter": "ص",
        "moshaf": [
          {
            "id": 195,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server14.mp3quran.net/musali/",
            "surah_total": 48,
            "moshaf_type": 116,
            "surah_list": "67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 176,
        "name": "خالد الشارخ",
        "letter": "خ",
        "moshaf": [
          {
            "id": 196,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server14.mp3quran.net/sharekh/",
            "surah_total": 64,
            "moshaf_type": 116,
            "surah_list": "2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 177,
        "name": "ناصر العصفور",
        "letter": "ن",
        "moshaf": [
          {
            "id": 197,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server14.mp3quran.net/alosfor/",
            "surah_total": 111,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 18,
        "name": "جمال شاكر عبدالله",
        "letter": "ج",
        "moshaf": [
          {
            "id": 18,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/jamal/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 181,
        "name": "أحمد السويلم",
        "letter": "أ",
        "moshaf": [
          {
            "id": 201,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server14.mp3quran.net/swlim/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 182,
        "name": "إسلام صبحي",
        "letter": "إ",
        "moshaf": [
          {
            "id": 202,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server14.mp3quran.net/islam/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 24,
            "moshaf_type": 116,
            "surah_list": "13,17,18,26,31,32,41,42,44,50,53,54,55,56,59,64,66,67,70,72,76,79,85,88"
          }
        ]
      },
      {
        "id": 184,
        "name": "بدر التركي",
        "letter": "ب",
        "moshaf": [
          {
            "id": 204,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/bader/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 186,
        "name": "هيثم الجدعاني",
        "letter": "ه",
        "moshaf": [
          {
            "id": 206,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/hitham/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 38,
            "moshaf_type": 116,
            "surah_list": "1,2,6,7,8,9,10,12,13,14,15,16,28,29,30,31,32,34,35,36,37,38,44,50,51,52,53,54,55,56,57,69,75,76,85,87,88,90"
          }
        ]
      },
      {
        "id": 187,
        "name": "ناصر الماجد",
        "letter": "ن",
        "moshaf": [
          {
            "id": 207,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server14.mp3quran.net/nasser_almajed/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 188,
        "name": "أحمد خليل شاهين",
        "letter": "أ",
        "moshaf": [
          {
            "id": 208,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/shaheen/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 189,
        "name": "محمد البخيت",
        "letter": "م",
        "moshaf": [
          {
            "id": 209,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server14.mp3quran.net/bukheet/",
            "surah_total": 109,
            "moshaf_type": 116,
            "surah_list": "1,2,3,7,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 19,
        "name": "حمد الدغريري",
        "letter": "ح",
        "moshaf": [
          {
            "id": 19,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/hamad/",
            "surah_total": 5,
            "moshaf_type": 116,
            "surah_list": "1,12,13,43,44"
          }
        ]
      },
      {
        "id": 190,
        "name": "سعد المقرن",
        "letter": "س",
        "moshaf": [
          {
            "id": 210,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/saad/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 191,
        "name": "أحمد النفيس",
        "letter": "أ",
        "moshaf": [
          {
            "id": 211,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/nufais/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 192,
        "name": "عمر الدريويز",
        "letter": "ع",
        "moshaf": [
          {
            "id": 212,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/darweez/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 61,
            "moshaf_type": 116,
            "surah_list": "1,12,13,15,18,19,25,32,36,38,44,47,48,50,51,52,53,54,56,61,62,63,64,66,67,68,70,71,72,73,74,75,76,78,79,80,82,85,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 197,
        "name": "عبدالعزيز العسيري",
        "letter": "ع",
        "moshaf": [
          {
            "id": 213,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/abdulazizasiri/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 21,
            "moshaf_type": 116,
            "surah_list": "2,3,5,11,12,13,14,17,18,36,45,51,55,57,67,70,71,73,78,86,88"
          }
        ]
      },
      {
        "id": 198,
        "name": "يونس اسويلص",
        "letter": "ي",
        "moshaf": [
          {
            "id": 214,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server16.mp3quran.net/souilass/Rewayat-Warsh-A-n-Nafi/",
            "surah_total": 13,
            "moshaf_type": 216,
            "surah_list": "1,18,19,25,50,51,56,57,67,73,91,97,112"
          }
        ]
      },
      {
        "id": 199,
        "name": "أحمد ديبان",
        "letter": "أ",
        "moshaf": [
          {
            "id": 298,
            "name": "ابن جماز عن أبي جعفر - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Ibn-Jammaz-A-n-Abi-Ja-far/",
            "surah_total": 11,
            "moshaf_type": 2016,
            "surah_list": "1,93,100,102,103,109,110,111,112,113,114"
          },
          {
            "id": 297,
            "name": "هشام عن ابي عامر - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Hesham-A-n-Abi-A-mer/",
            "surah_total": 27,
            "moshaf_type": 1916,
            "surah_list": "1,2,3,4,5,6,7,8,9,13,85,90,92,93,94,95,99,100,101,103,105,107,108,110,112,113,114"
          },
          {
            "id": 296,
            "name": "خلف عن حمزة - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Khalaf-A-n-Hamzah/",
            "surah_total": 6,
            "moshaf_type": 316,
            "surah_list": "94,97,101,107,108,109"
          },
          {
            "id": 295,
            "name": "الدوري عن الكسائي - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-AlDorai-A-n-Al-Kisa-ai/",
            "surah_total": 13,
            "moshaf_type": 1216,
            "surah_list": "1,94,95,100,103,105,106,108,109,110,112,113,114"
          },
          {
            "id": 294,
            "name": "السوسي عن أبي عمرو - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Assosi-A-n-Abi-Amr/",
            "surah_total": 19,
            "moshaf_type": 716,
            "surah_list": "1,82,86,87,88,93,94,95,99,102,103,106,108,109,110,111,112,113,114"
          },
          {
            "id": 293,
            "name": "ابن ذكوان عن ابن عامر - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Ibn-Thakwan-A-n-Ibn-Amer/",
            "surah_total": 33,
            "moshaf_type": 1516,
            "surah_list": "1,2,3,4,5,6,7,8,9,13,77,83,85,86,88,90,92,93,94,95,96,97,99,100,101,103,105,107,108,109,112,113,114"
          },
          {
            "id": 285,
            "name": "ورش عن نافع من طريق الأزرق - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Warsh-A-n-Nafi-Men-Tariq-Alazraq/",
            "surah_total": 114,
            "moshaf_type": 1816,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 264,
            "name": "الدوري عن أبي عمرو - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Aldori-A-n-Abi-Amr/",
            "surah_total": 114,
            "moshaf_type": 1316,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 259,
            "name": "قالون عن نافع - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Qalon-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 516,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 258,
            "name": "البزي عن ابن كثير - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Albizi-A-n-Ibn-Katheer/",
            "surah_total": 114,
            "moshaf_type": 1616,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 257,
            "name": "قنبل عن ابن كثير - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Qunbol-A-n-Ibn-Katheer/",
            "surah_total": 114,
            "moshaf_type": 1716,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 243,
            "name": "شعبة  عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Sho-bah-A-n-Asim/",
            "surah_total": 114,
            "moshaf_type": 1416,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 216,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/deban/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 2,
        "name": "إبراهيم الجبرين",
        "letter": "إ",
        "moshaf": [
          {
            "id": 2,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/jbreen/",
            "surah_total": 99,
            "moshaf_type": 116,
            "surah_list": "1,3,4,7,8,10,12,13,14,15,16,17,18,19,20,21,23,25,27,31,32,33,34,36,37,38,39,40,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 20,
        "name": "خالد الجليل",
        "letter": "خ",
        "moshaf": [
          {
            "id": 20,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/jleel/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 200,
        "name": "عبدالله كامل",
        "letter": "ع",
        "moshaf": [
          {
            "id": 220,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/kamel/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 201,
        "name": "بيشه وا قادر الكردي",
        "letter": "ب",
        "moshaf": [
          {
            "id": 222,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/peshawa/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 204,
        "name": "نذير المالكي",
        "letter": "ن",
        "moshaf": [
          {
            "id": 226,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net//nathier/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 206,
        "name": "عكاشة كميني",
        "letter": "ع",
        "moshaf": [
          {
            "id": 280,
            "name": "البزي عن ابن كثير - مرتل",
            "server": "https://server16.mp3quran.net/okasha/Rewayat-Albizi-A-n-Ibn-Katheer/",
            "surah_total": 3,
            "moshaf_type": 1616,
            "surah_list": "2,3,4"
          },
          {
            "id": 244,
            "name": "الدوري عن الكسائي - مرتل",
            "server": "https://server16.mp3quran.net/okasha/Rewayat-AlDorai-A-n-Al-Kisa-ai/",
            "surah_total": 38,
            "moshaf_type": 1216,
            "surah_list": "17,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 208,
        "name": "هيثم الدخين",
        "letter": "ه",
        "moshaf": [
          {
            "id": 246,
            "name": "حفص عن عاصم - 19",
            "server": "https://server16.mp3quran.net/h_dukhain/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 119,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 21,
        "name": "خالد القحطاني",
        "letter": "خ",
        "moshaf": [
          {
            "id": 21,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/qht/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 22,
        "name": "خالد عبدالكافي",
        "letter": "خ",
        "moshaf": [
          {
            "id": 22,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/kafi/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 221,
        "name": "محمد أبو سنينة",
        "letter": "م",
        "moshaf": [
          {
            "id": 247,
            "name": "قالون عن نافع - مرتل",
            "server": "https://server16.mp3quran.net/sneineh/Rewayat-Qalon-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 516,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 222,
        "name": "محمد الأمين قنيوة",
        "letter": "م",
        "moshaf": [
          {
            "id": 248,
            "name": "قالون عن نافع - مرتل",
            "server": "https://server16.mp3quran.net/qeniwa/Rewayat-Qalon-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 516,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 223,
        "name": "محمود عبدالحكم",
        "letter": "م",
        "moshaf": [
          {
            "id": 250,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/m_abdelhakam/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 113,
            "moshaf_type": 116,
            "surah_list": "1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 227,
        "name": "محمد سايد",
        "letter": "م",
        "moshaf": [
          {
            "id": 251,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server16.mp3quran.net/m_sayed/Rewayat-Warsh-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 216,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 228,
        "name": "عبدالرحمن السويّد",
        "letter": "ع",
        "moshaf": [
          {
            "id": 252,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a_swaiyd/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 85,
            "moshaf_type": 116,
            "surah_list": "2,11,15,18,19,25,34,35,36,37,38,39,40,41,42,43,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 229,
        "name": "عبدالإله بن عون",
        "letter": "ع",
        "moshaf": [
          {
            "id": 253,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a_binaoun/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 23,
        "name": "خالد الوهيبي",
        "letter": "خ",
        "moshaf": [
          {
            "id": 23,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/whabi/",
            "surah_total": 11,
            "moshaf_type": 116,
            "surah_list": "12,13,14,16,19,24,25,29,30,31,32"
          }
        ]
      },
      {
        "id": 230,
        "name": "أحمد طالب بن حميد",
        "letter": "أ",
        "moshaf": [
          {
            "id": 254,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a_binhameed/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 82,
            "moshaf_type": 116,
            "surah_list": "1,2,5,6,8,10,12,18,20,29,30,31,32,35,37,38,44,45,49,50,51,52,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 231,
        "name": "نورين محمد صديق",
        "letter": "ن",
        "moshaf": [
          {
            "id": 255,
            "name": "الدوري عن أبي عمرو - مرتل",
            "server": "https://server16.mp3quran.net/nourin_siddig/Rewayat-Aldori-A-n-Abi-Amr/",
            "surah_total": 114,
            "moshaf_type": 1316,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 232,
        "name": "عبدالله بخاري",
        "letter": "ع",
        "moshaf": [
          {
            "id": 256,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a_bukhari/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 80,
            "moshaf_type": 116,
            "surah_list": "1,2,36,37,38,39,40,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 233,
        "name": "عبدالعزيز التركي",
        "letter": "ع",
        "moshaf": [
          {
            "id": 260,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a_turki/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 66,
            "moshaf_type": 116,
            "surah_list": "1,2,6,7,8,9,10,11,12,13,18,21,24,25,26,27,28,29,30,31,39,40,41,44,46,48,49,54,69,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 234,
        "name": "مختار الحاج",
        "letter": "م",
        "moshaf": [
          {
            "id": 261,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/mukhtar_haj/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 16,
            "moshaf_type": 116,
            "surah_list": "1,2,8,12,14,16,18,35,36,41,42,47,55,56,67,68"
          }
        ]
      },
      {
        "id": 235,
        "name": "عبدالله عبدل",
        "letter": "ع",
        "moshaf": [
          {
            "id": 263,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a_abdl/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 236,
        "name": "مروان العكري",
        "letter": "م",
        "moshaf": [
          {
            "id": 271,
            "name": "قالون عن نافع - مرتل",
            "server": "https://server16.mp3quran.net/m_akri/Rewayat-Qalon-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 516,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 237,
        "name": "أحمد عيسى المعصراوي",
        "letter": "أ",
        "moshaf": [
          {
            "id": 274,
            "name": "قراءة يعقوب الحضرمي بروايتي رويس وروح - مرتل",
            "server": "https://server16.mp3quran.net/a_maasaraawi/Rewayat-Rawh-A-n-Yakoob-Alhadrami/",
            "surah_total": 64,
            "moshaf_type": 916,
            "surah_list": "51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 273,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a_maasaraawi/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 238,
        "name": "إبراهيم كشيدان",
        "letter": "إ",
        "moshaf": [
          {
            "id": 275,
            "name": "قالون عن نافع - مرتل",
            "server": "https://server16.mp3quran.net/i_kshidan/Rewayat-Qalon-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 516,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 239,
        "name": "هاشم أبو دلال",
        "letter": "ه",
        "moshaf": [
          {
            "id": 276,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/h_abudalal/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 24,
        "name": "خليفة الطنيجي",
        "letter": "خ",
        "moshaf": [
          {
            "id": 24,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/tnjy/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 240,
        "name": "فؤاد الخامري",
        "letter": "ف",
        "moshaf": [
          {
            "id": 277,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/f_khamery/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 241,
        "name": "سيد أحمد هاشمي",
        "letter": "س",
        "moshaf": [
          {
            "id": 278,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/s_hashemi/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 242,
        "name": "خالد كريم محمدي",
        "letter": "خ",
        "moshaf": [
          {
            "id": 279,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/kh_mohammadi/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 243,
        "name": "صالح الشمراني",
        "letter": "ص",
        "moshaf": [
          {
            "id": 281,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/shamrani/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 244,
        "name": "مال الله عبدالرحمن الجابر",
        "letter": "م",
        "moshaf": [
          {
            "id": 282,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/mal-allah_jaber/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 245,
        "name": "سلمان الصديق",
        "letter": "س",
        "moshaf": [
          {
            "id": 283,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/s_sadeiq/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 246,
        "name": "حسن صالح",
        "letter": "ح",
        "moshaf": [
          {
            "id": 284,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/h_saleh/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 247,
        "name": "عبدالرحمن الشحات",
        "letter": "ع",
        "moshaf": [
          {
            "id": 286,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a_alshahhat/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 248,
        "name": "عيسى عمر سناكو",
        "letter": "ع",
        "moshaf": [
          {
            "id": 287,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/i_sanankoua/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 26,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,6,12,14,18,20,32,35,40,50,56,57,58,63,67,71,78,87,88,91,112,113,114"
          }
        ]
      },
      {
        "id": 249,
        "name": "هارون بقائي",
        "letter": "ه",
        "moshaf": [
          {
            "id": 290,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/h_baqai/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 51,
            "moshaf_type": 116,
            "surah_list": "1,56,62,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 25,
        "name": "داود حمزة",
        "letter": "د",
        "moshaf": [
          {
            "id": 25,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/hamza/",
            "surah_total": 86,
            "moshaf_type": 116,
            "surah_list": "2,3,5,6,7,8,10,12,14,16,17,18,19,20,21,23,24,25,27,28,29,31,33,34,35,36,37,38,40,41,42,46,47,48,50,52,53,54,56,58,60,61,63,65,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,92,93,96,97,98,101,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 250,
        "name": "صالح القريشي",
        "letter": "ص",
        "moshaf": [
          {
            "id": 291,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/s_alquraishi/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 251,
        "name": "فيصل الهاجري",
        "letter": "ف",
        "moshaf": [
          {
            "id": 292,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/f_hajry/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 252,
        "name": "أنس العمادي",
        "letter": "أ",
        "moshaf": [
          {
            "id": 299,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a_alemadi/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 253,
        "name": "عبدالملك العسكر",
        "letter": "ع",
        "moshaf": [
          {
            "id": 300,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a_alaskar/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 6,
            "moshaf_type": 116,
            "surah_list": "36,40,69,70,71,75"
          }
        ]
      },
      {
        "id": 26,
        "name": "رشيد إفراد",
        "letter": "ر",
        "moshaf": [
          {
            "id": 26,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server12.mp3quran.net/ifrad/",
            "surah_total": 15,
            "moshaf_type": 216,
            "surah_list": "1,18,25,26,27,28,29,31,33,35,37,38,41,42,71"
          }
        ]
      },
      {
        "id": 27,
        "name": "رشيد بلعالية",
        "letter": "ر",
        "moshaf": [
          {
            "id": 217,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/bl3/",
            "surah_total": 5,
            "moshaf_type": 116,
            "surah_list": "46,47,48,49,50"
          },
          {
            "id": 27,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server6.mp3quran.net/bl3/Rewayat-Warsh-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 216,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 28,
        "name": "زكريا حمامة",
        "letter": "ز",
        "moshaf": [
          {
            "id": 28,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/zakariya/",
            "surah_total": 7,
            "moshaf_type": 116,
            "surah_list": "32,36,44,56,67,76,85"
          }
        ]
      },
      {
        "id": 285,
        "name": "عبدالكريم الحازمي",
        "letter": "ع",
        "moshaf": [
          {
            "id": 313,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a_alhazmi/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 286,
        "name": "هشام الهراز",
        "letter": "ه",
        "moshaf": [
          {
            "id": 312,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server16.mp3quran.net/H-Lharraz/Rewayat-Warsh-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 216,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 287,
        "name": "عبدالله المشعل",
        "letter": "ع",
        "moshaf": [
          {
            "id": 314,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/a-almishal/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 5,
            "moshaf_type": 116,
            "surah_list": "14,49,49,51,51"
          }
        ]
      },
      {
        "id": 29,
        "name": "سعد الغامدي",
        "letter": "س",
        "moshaf": [
          {
            "id": 29,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server7.mp3quran.net/s_gmd/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 3,
        "name": "إبراهيم العسيري",
        "letter": "إ",
        "moshaf": [
          {
            "id": 3,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/3siri/",
            "surah_total": 27,
            "moshaf_type": 116,
            "surah_list": "1,3,4,5,8,12,13,14,17,18,19,20,21,23,27,34,36,38,51,53,54,56,67,69,70,75,76"
          }
        ]
      },
      {
        "id": 30,
        "name": "سعود الشريم",
        "letter": "س",
        "moshaf": [
          {
            "id": 30,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server7.mp3quran.net/shur/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 301,
        "name": "عبدالعزيز سحيم",
        "letter": "ع",
        "moshaf": [
          {
            "id": 315,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server16.mp3quran.net/a_sheim/Rewayat-Warsh-A-n-Nafi/",
            "surah_total": 10,
            "moshaf_type": 216,
            "surah_list": "1,18,31,50,56,57,67,72,75,112"
          }
        ]
      },
      {
        "id": 31,
        "name": "سهل ياسين",
        "letter": "س",
        "moshaf": [
          {
            "id": 31,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/shl/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 32,
        "name": "زكي داغستاني",
        "letter": "ز",
        "moshaf": [
          {
            "id": 32,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/zaki/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 33,
        "name": "سامي الحسن",
        "letter": "س",
        "moshaf": [
          {
            "id": 33,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/sami_hsn/",
            "surah_total": 27,
            "moshaf_type": 116,
            "surah_list": "1,19,20,24,26,27,32,34,55,81,82,86,87,88,91,92,93,94,95,97,102,104,105,109,110,111,112"
          }
        ]
      },
      {
        "id": 34,
        "name": "سامي الدوسري",
        "letter": "س",
        "moshaf": [
          {
            "id": 34,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/sami_dosr/",
            "surah_total": 41,
            "moshaf_type": 116,
            "surah_list": "29,30,31,32,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 35,
        "name": "سيد رمضان",
        "letter": "س",
        "moshaf": [
          {
            "id": 35,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/sayed/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 36,
        "name": "شعبان الصياد",
        "letter": "ش",
        "moshaf": [
          {
            "id": 36,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/shaban/",
            "surah_total": 20,
            "moshaf_type": 116,
            "surah_list": "3,35,87,88,89,90,91,92,94,95,96,97,98,99,100,101,102,103,104,105"
          }
        ]
      },
      {
        "id": 37,
        "name": "شيرزاد عبدالرحمن طاهر",
        "letter": "ش",
        "moshaf": [
          {
            "id": 37,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/taher/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 38,
        "name": "صابر عبدالحكم",
        "letter": "ص",
        "moshaf": [
          {
            "id": 38,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/hkm/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 39,
        "name": "صالح الصاهود",
        "letter": "ص",
        "moshaf": [
          {
            "id": 39,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/sahood/",
            "surah_total": 110,
            "moshaf_type": 116,
            "surah_list": "1,3,4,5,6,7,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 4,
        "name": "شيخ أبو بكر الشاطري",
        "letter": "ش",
        "moshaf": [
          {
            "id": 4,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/shatri/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 40,
        "name": "صالح آل طالب",
        "letter": "ص",
        "moshaf": [
          {
            "id": 40,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/tlb/",
            "surah_total": 32,
            "moshaf_type": 116,
            "surah_list": "1,25,34,38,39,44,45,46,47,55,56,57,58,59,60,61,70,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86"
          }
        ]
      },
      {
        "id": 41,
        "name": "صالح الهبدان",
        "letter": "ص",
        "moshaf": [
          {
            "id": 41,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/habdan/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 42,
        "name": "صلاح البدير",
        "letter": "ص",
        "moshaf": [
          {
            "id": 42,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/s_bud/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 43,
        "name": "صلاح الهاشم",
        "letter": "ص",
        "moshaf": [
          {
            "id": 44,
            "name": "قالون عن نافع - مرتل",
            "server": "https://server12.mp3quran.net/salah_hashim_m/Rewayat-Qalon-A-n-Nafi/",
            "surah_total": 7,
            "moshaf_type": 516,
            "surah_list": "1,12,40,50,53,75,76"
          },
          {
            "id": 43,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/salah_hashim_m/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 44,
        "name": "صلاح بو خاطر",
        "letter": "ص",
        "moshaf": [
          {
            "id": 45,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/bu_khtr/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 45,
        "name": "عادل ريان",
        "letter": "ع",
        "moshaf": [
          {
            "id": 46,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/ryan/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 46,
        "name": "عبدالبارئ الثبيتي",
        "letter": "ع",
        "moshaf": [
          {
            "id": 47,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/thubti/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 47,
        "name": "عبدالبارئ محمد",
        "letter": "ع",
        "moshaf": [
          {
            "id": 141,
            "name": "المصحف المعلم - المصحف المعلم",
            "server": "https://server12.mp3quran.net/bari/Almusshaf-Al-Mo-lim/",
            "surah_total": 91,
            "moshaf_type": 418,
            "surah_list": "1,3,5,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 48,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/bari/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 48,
        "name": "عبدالباسط عبدالصمد",
        "letter": "ع",
        "moshaf": [
          {
            "id": 51,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server7.mp3quran.net/basit/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 50,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server7.mp3quran.net/basit/Rewayat-Warsh-A-n-Nafi/",
            "surah_total": 114,
            "moshaf_type": 216,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 49,
            "name": "المصحف المجود - المصحف المجود",
            "server": "https://server7.mp3quran.net/basit/Almusshaf-Al-Mojawwad/",
            "surah_total": 114,
            "moshaf_type": 617,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 49,
        "name": "عبدالرحمن السديس",
        "letter": "ع",
        "moshaf": [
          {
            "id": 52,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/sds/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 5,
        "name": "أحمد بن علي العجمي",
        "letter": "أ",
        "moshaf": [
          {
            "id": 5,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server10.mp3quran.net/ajm/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 50,
        "name": "عبدالعزيز الأحمد",
        "letter": "ع",
        "moshaf": [
          {
            "id": 53,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/a_ahmed/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 51,
        "name": "عبدالعزيز الزهراني",
        "letter": "ع",
        "moshaf": [
          {
            "id": 54,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/zahrani/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 52,
        "name": "عبدالله البريمي",
        "letter": "ع",
        "moshaf": [
          {
            "id": 55,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/brmi/",
            "surah_total": 41,
            "moshaf_type": 116,
            "surah_list": "1,49,50,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95"
          }
        ]
      },
      {
        "id": 53,
        "name": "عبدالله البعيجان",
        "letter": "ع",
        "moshaf": [
          {
            "id": 56,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/buajan/",
            "surah_total": 83,
            "moshaf_type": 116,
            "surah_list": "1,8,10,11,12,13,14,15,17,18,19,20,21,22,23,24,25,26,28,29,30,32,33,34,35,39,40,41,42,43,48,49,50,55,56,57,60,62,63,64,65,66,67,68,69,73,75,76,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,97,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 54,
        "name": "عبدالله المطرود",
        "letter": "ع",
        "moshaf": [
          {
            "id": 57,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/mtrod/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 55,
        "name": "عبدالله بصفر",
        "letter": "ع",
        "moshaf": [
          {
            "id": 58,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/bsfr/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 56,
        "name": "عبدالله خياط",
        "letter": "ع",
        "moshaf": [
          {
            "id": 59,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/kyat/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 57,
        "name": "عبدالله عواد الجهني",
        "letter": "ع",
        "moshaf": [
          {
            "id": 60,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server13.mp3quran.net/jhn/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 58,
        "name": "عبدالله غيلان",
        "letter": "ع",
        "moshaf": [
          {
            "id": 61,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/gulan/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 59,
        "name": "عبدالرشيد صوفي",
        "letter": "ع",
        "moshaf": [
          {
            "id": 219,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server16.mp3quran.net/soufi/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 63,
            "name": "السوسي عن أبي عمرو - مرتل",
            "server": "https://server16.mp3quran.net/soufi/Rewayat-Assosi-A-n-Abi-Amr/",
            "surah_total": 114,
            "moshaf_type": 716,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 62,
            "name": "خلف عن حمزة - مرتل",
            "server": "https://server16.mp3quran.net/soufi/Rewayat-Khalaf-A-n-Hamzah/",
            "surah_total": 114,
            "moshaf_type": 316,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 6,
        "name": "أحمد الحواشي",
        "letter": "أ",
        "moshaf": [
          {
            "id": 6,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/hawashi/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 60,
        "name": "عبدالمحسن الحارثي",
        "letter": "ع",
        "moshaf": [
          {
            "id": 64,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/mohsin_harthi/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 61,
        "name": "عبدالمحسن القاسم",
        "letter": "ع",
        "moshaf": [
          {
            "id": 65,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/qasm/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 62,
        "name": "عبدالمحسن العسكر",
        "letter": "ع",
        "moshaf": [
          {
            "id": 66,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/askr/",
            "surah_total": 4,
            "moshaf_type": 116,
            "surah_list": "1,2,7,55"
          }
        ]
      },
      {
        "id": 63,
        "name": "عبدالمحسن العبيكان",
        "letter": "ع",
        "moshaf": [
          {
            "id": 67,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/obk/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 64,
        "name": "عبدالهادي أحمد كناكري",
        "letter": "ع",
        "moshaf": [
          {
            "id": 68,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/kanakeri/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 65,
        "name": "عبدالودود حنيف",
        "letter": "ع",
        "moshaf": [
          {
            "id": 69,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/wdod/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 66,
        "name": "عبدالولي الأركاني",
        "letter": "ع",
        "moshaf": [
          {
            "id": 70,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/arkani/",
            "surah_total": 31,
            "moshaf_type": 116,
            "surah_list": "1,2,3,7,12,14,15,16,17,18,19,20,31,36,37,39,40,49,55,56,67,68,69,70,71,72,73,74,75,76,77"
          }
        ]
      },
      {
        "id": 67,
        "name": "علي أبو هاشم",
        "letter": "ع",
        "moshaf": [
          {
            "id": 71,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/abo_hashim/",
            "surah_total": 8,
            "moshaf_type": 116,
            "surah_list": "50,51,52,53,54,55,56,57"
          }
        ]
      },
      {
        "id": 68,
        "name": "علي بن عبدالرحمن الحذيفي",
        "letter": "ع",
        "moshaf": [
          {
            "id": 289,
            "name": "شعبة  عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/hthfi/Rewayat-Sho-bah-A-n-Asim/",
            "surah_total": 114,
            "moshaf_type": 1416,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 218,
            "name": "قالون عن نافع - مرتل",
            "server": "https://server9.mp3quran.net/huthifi_qalon/",
            "surah_total": 114,
            "moshaf_type": 516,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 72,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/hthfi/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 7,
        "name": "أحمد سعود",
        "letter": "أ",
        "moshaf": [
          {
            "id": 7,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/saud/",
            "surah_total": 30,
            "moshaf_type": 116,
            "surah_list": "85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 70,
        "name": "علي جابر",
        "letter": "ع",
        "moshaf": [
          {
            "id": 74,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/a_jbr/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 71,
        "name": "علي حجاج السويسي",
        "letter": "ع",
        "moshaf": [
          {
            "id": 75,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/hajjaj/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 72,
        "name": "عماد زهير حافظ",
        "letter": "ع",
        "moshaf": [
          {
            "id": 76,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/hafz/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 73,
        "name": "عمر القزابري",
        "letter": "ع",
        "moshaf": [
          {
            "id": 77,
            "name": "ورش عن نافع - مرتل",
            "server": "https://server9.mp3quran.net/omar_warsh/",
            "surah_total": 114,
            "moshaf_type": 216,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 74,
        "name": "فارس عباد",
        "letter": "ف",
        "moshaf": [
          {
            "id": 78,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/frs_a/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 75,
        "name": "فهد العتيبي",
        "letter": "ف",
        "moshaf": [
          {
            "id": 79,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/fahad_otibi/",
            "surah_total": 8,
            "moshaf_type": 116,
            "surah_list": "50,56,69,72,73,74,75,76"
          }
        ]
      },
      {
        "id": 76,
        "name": "فهد الكندري",
        "letter": "ف",
        "moshaf": [
          {
            "id": 80,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/kndri/",
            "surah_total": 53,
            "moshaf_type": 116,
            "surah_list": "12,36,51,52,53,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 77,
        "name": "فواز الكعبي",
        "letter": "ف",
        "moshaf": [
          {
            "id": 81,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/fawaz/",
            "surah_total": 44,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,21,22,23,25,27,28,29,30,36,39,40,49,54,55,56,57,58,59,62,63,66,67,73,87,109,112"
          }
        ]
      },
      {
        "id": 78,
        "name": "لافي العوني",
        "letter": "ل",
        "moshaf": [
          {
            "id": 82,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/lafi/",
            "surah_total": 52,
            "moshaf_type": 116,
            "surah_list": "3,12,15,16,19,20,27,28,30,31,32,33,36,37,38,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 79,
        "name": "ناصر القطامي",
        "letter": "ن",
        "moshaf": [
          {
            "id": 83,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/qtm/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 8,
        "name": "أحمد صابر",
        "letter": "أ",
        "moshaf": [
          {
            "id": 8,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/saber/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 80,
        "name": "نبيل الرفاعي",
        "letter": "ن",
        "moshaf": [
          {
            "id": 84,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/nabil/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 81,
        "name": "نعمة الحسان",
        "letter": "ن",
        "moshaf": [
          {
            "id": 85,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/namh/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 82,
        "name": "هاني الرفاعي",
        "letter": "ه",
        "moshaf": [
          {
            "id": 86,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/hani/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 83,
        "name": "وليد الدليمي",
        "letter": "و",
        "moshaf": [
          {
            "id": 87,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/dlami/",
            "surah_total": 51,
            "moshaf_type": 116,
            "surah_list": "1,12,17,31,39,41,45,48,49,50,51,52,53,54,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 84,
        "name": "وليد النائحي",
        "letter": "و",
        "moshaf": [
          {
            "id": 88,
            "name": "قالون عن نافع من طريق أبي نشيط - مرتل",
            "server": "https://server9.mp3quran.net/waleed/",
            "surah_total": 114,
            "moshaf_type": 816,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 85,
        "name": "ياسر الدوسري",
        "letter": "ي",
        "moshaf": [
          {
            "id": 89,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/yasser/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 86,
        "name": "ياسر القرشي",
        "letter": "ي",
        "moshaf": [
          {
            "id": 90,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/qurashi/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 87,
        "name": "ياسر الفيلكاوي",
        "letter": "ي",
        "moshaf": [
          {
            "id": 91,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server6.mp3quran.net/fyl/",
            "surah_total": 52,
            "moshaf_type": 116,
            "surah_list": "1,2,25,44,56,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,113,114"
          }
        ]
      },
      {
        "id": 88,
        "name": "ياسر المزروعي",
        "letter": "ي",
        "moshaf": [
          {
            "id": 92,
            "name": "قراءة يعقوب الحضرمي بروايتي رويس وروح - مرتل",
            "server": "https://server9.mp3quran.net/mzroyee/",
            "surah_total": 114,
            "moshaf_type": 916,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 89,
        "name": "يحيى حوا",
        "letter": "ي",
        "moshaf": [
          {
            "id": 93,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/yahya/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 9,
        "name": "أحمد نعينع",
        "letter": "أ",
        "moshaf": [
          {
            "id": 9,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/ahmad_nu/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 90,
        "name": "يوسف الشويعي",
        "letter": "ي",
        "moshaf": [
          {
            "id": 94,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/yousef/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 91,
        "name": "ماجد العنزي",
        "letter": "م",
        "moshaf": [
          {
            "id": 95,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/majd_onazi/",
            "surah_total": 69,
            "moshaf_type": 116,
            "surah_list": "1,13,14,17,18,19,30,31,32,34,43,44,45,49,50,51,53,55,56,59,60,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 92,
        "name": "مالك شيبة الحمد",
        "letter": "م",
        "moshaf": [
          {
            "id": 96,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server9.mp3quran.net/shaibat/",
            "surah_total": 37,
            "moshaf_type": 116,
            "surah_list": "78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 93,
        "name": "ماهر المعيقلي",
        "letter": "م",
        "moshaf": [
          {
            "id": 249,
            "name": "المصحف المجود - المصحف المجود",
            "server": "https://server12.mp3quran.net/maher/Almusshaf-Al-Mojawwad/",
            "surah_total": 114,
            "moshaf_type": 617,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 98,
            "name": "المصحف المعلم - المصحف المعلم",
            "server": "https://server12.mp3quran.net/maher/Almusshaf-Al-Mo-lim/",
            "surah_total": 38,
            "moshaf_type": 418,
            "surah_list": "1,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 97,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/maher/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 94,
        "name": "محمد الأيراوي",
        "letter": "م",
        "moshaf": [
          {
            "id": 99,
            "name": "ورش عن نافع من طريق الأزرق - مرتل",
            "server": "https://server6.mp3quran.net/earawi/",
            "surah_total": 112,
            "moshaf_type": 1816,
            "surah_list": "1,2,3,4,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 95,
        "name": "محمد البراك",
        "letter": "م",
        "moshaf": [
          {
            "id": 100,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server13.mp3quran.net/braak/",
            "surah_total": 62,
            "moshaf_type": 116,
            "surah_list": "1,12,36,37,44,45,50,51,52,53,54,55,56,57,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 96,
        "name": "محمد الطبلاوي",
        "letter": "م",
        "moshaf": [
          {
            "id": 101,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server12.mp3quran.net/tblawi/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 97,
        "name": "محمد اللحيدان",
        "letter": "م",
        "moshaf": [
          {
            "id": 102,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/lhdan/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 98,
        "name": "محمد المحيسني",
        "letter": "م",
        "moshaf": [
          {
            "id": 103,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server11.mp3quran.net/mhsny/",
            "surah_total": 113,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      },
      {
        "id": 99,
        "name": "محمد أيوب",
        "letter": "م",
        "moshaf": [
          {
            "id": 221,
            "name": "حفص عن عاصم - 19",
            "server": "https://server16.mp3quran.net/ayyoub2/Rewayat-Hafs-A-n-Assem/",
            "surah_total": 114,
            "moshaf_type": 119,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          },
          {
            "id": 104,
            "name": "حفص عن عاصم - مرتل",
            "server": "https://server8.mp3quran.net/ayyub/",
            "surah_total": 114,
            "moshaf_type": 116,
            "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
          }
        ]
      }
    ];
  

handleReciters(reciters);

console.log(reciters);

module.exports = reciters;    