import Inputs from "../components/Inputs"
import Header from "../components/Header"

const Home = ()=>{
console.log("newwwwwww enterrrrrrrr")
    return (

        <div>
            <Header/>
            <Inputs/>
        </div>
    )

}

export default Home;
