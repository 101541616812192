import Header from "../components/Header";
import SurahInput from "../components/SurahInput";

const Translate = ()=>{

    return (

    <div>
        <Header/>
        <SurahInput/>
    </div>)

}


export default Translate;