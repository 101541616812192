function handleReciters(reciters){

  reciters.sort(function(a, b){if(a.name < b.name) { return -1; }
  if(a.name > b.name) { return 1; }
  return 0;});


  let temp = [];

  reciters.forEach(function(item) {
  
  item.moshaf.forEach(function(item2) {
    if(item2.surah_total===114) {
      temp.push(item2);
    }
  });
  
  item.moshaf=temp;
  temp=[];
});

reciters.forEach(function(item) {

  if(item.moshaf.length>0)temp.push(item);

});

while (reciters.length > 0) {
  reciters.pop();
}
temp.forEach(e=>{reciters.push(e);}
)
  
}


const Reciters = [
    {
      "id": 1,
      "name": "Ibrahim Al-Akdar",
      "letter": "I",
      "moshaf": [
        {
          "id": 1,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/akdr/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 10,
      "name": "Akram Alalaqmi",
      "letter": "A",
      "moshaf": [
        {
          "id": 10,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/akrm/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 100,
      "name": "Mohammad Saleh Alim Shah",
      "letter": "M",
      "moshaf": [
        {
          "id": 105,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/shah/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 101,
      "name": "Mohammed Jibreel",
      "letter": "M",
      "moshaf": [
        {
          "id": 106,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/jbrl/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 102,
      "name": "Mohammed Siddiq Al-Minshawi",
      "letter": "M",
      "moshaf": [
        {
          "id": 109,
          "name": "Almusshaf Al Mo'lim - Almusshaf Al Mo'lim",
          "server": "https://server10.mp3quran.net/minsh/Almusshaf-Al-Mo-lim/",
          "surah_total": 114,
          "moshaf_type": 418,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 108,
          "name": "Almusshaf Al Mojawwad - Almusshaf Al Mojawwad",
          "server": "https://server10.mp3quran.net/minsh/Almusshaf-Al-Mojawwad/",
          "surah_total": 114,
          "moshaf_type": 617,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 107,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/minsh/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 103,
      "name": "Mohammad Abdullkarem",
      "letter": "M",
      "moshaf": [
        {
          "id": 171,
          "name": "Rewayat Warsh A'n Nafi' Men  Tariq Abi Baker Alasbahani - Murattal",
          "server": "https://server12.mp3quran.net/m_krm/Rewayat-Warsh-A-n-Nafi-Men-Tariq-Abi-Baker-Alasbahani/",
          "surah_total": 114,
          "moshaf_type": 1016,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 110,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/m_krm/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 104,
      "name": "Mohammad Al-Abdullah",
      "letter": "M",
      "moshaf": [
        {
          "id": 112,
          "name": "Rewayat AlDorai A'n Al-Kisa'ai - Murattal",
          "server": "https://server9.mp3quran.net/abdullah/Rewayat-AlDorai-A-n-Al-Kisa-ai/",
          "surah_total": 114,
          "moshaf_type": 1216,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 111,
          "name": "Rewayat Albizi and Qunbol A'n Ibn Katheer - Murattal",
          "server": "https://server9.mp3quran.net/abdullah/",
          "surah_total": 114,
          "moshaf_type": 1116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 105,
      "name": "Mahmoud Khalil Al-Hussary",
      "letter": "M",
      "moshaf": [
        {
          "id": 224,
          "name": "Rewayat Qalon A'n Nafi' - Murattal",
          "server": "https://server13.mp3quran.net/husr/Rewayat-Qalon-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 516,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 223,
          "name": "Rewayat Aldori A'n Abi Amr - Murattal",
          "server": "https://server13.mp3quran.net/husr/Rewayat-Aldori-A-n-Abi-Amr/",
          "surah_total": 114,
          "moshaf_type": 1316,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 115,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server13.mp3quran.net/husr/Rewayat-Warsh-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 216,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 114,
          "name": "Almusshaf Al Mojawwad - Almusshaf Al Mojawwad",
          "server": "https://server13.mp3quran.net/husr/Almusshaf-Al-Mojawwad/",
          "surah_total": 114,
          "moshaf_type": 617,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 113,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server13.mp3quran.net/husr/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 106,
      "name": "Mahmoud Ali  Albanna",
      "letter": "M",
      "moshaf": [
        {
          "id": 117,
          "name": "Almusshaf Al Mojawwad - Almusshaf Al Mojawwad",
          "server": "https://server8.mp3quran.net/bna/Almusshaf-Al-Mojawwad/",
          "surah_total": 114,
          "moshaf_type": 617,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 116,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/bna/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 107,
      "name": "Mishary Alafasi",
      "letter": "M",
      "moshaf": [
        {
          "id": 119,
          "name": "Rewayat AlDorai A'n Al-Kisa'ai - Murattal",
          "server": "https://server8.mp3quran.net/afs/Rewayat-AlDorai-A-n-Al-Kisa-ai/",
          "surah_total": 5,
          "moshaf_type": 1216,
          "surah_list": "14,25,87,97,99"
        },
        {
          "id": 118,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/afs/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 108,
      "name": "Mustafa Ismail",
      "letter": "M",
      "moshaf": [
        {
          "id": 272,
          "name": "Almusshaf Al Mojawwad - Almusshaf Al Mojawwad",
          "server": "https://server8.mp3quran.net/mustafa/Almusshaf-Al-Mojawwad/",
          "surah_total": 114,
          "moshaf_type": 617,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 120,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/mustafa/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 109,
      "name": "Mustafa Al-Lahoni",
      "letter": "M",
      "moshaf": [
        {
          "id": 121,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/lahoni/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 11,
      "name": "Alhusayni Al-Azazi",
      "letter": "A",
      "moshaf": [
        {
          "id": 11,
          "name": "Almusshaf Al Mo'lim - Almusshaf Al Mo'lim",
          "server": "https://server8.mp3quran.net/3zazi/",
          "surah_total": 57,
          "moshaf_type": 418,
          "surah_list": "58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 110,
      "name": "Mustafa raad Alazawy",
      "letter": "M",
      "moshaf": [
        {
          "id": 122,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/ra3ad/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 111,
      "name": "Muamar (From Indonesia)",
      "letter": "M",
      "moshaf": [
        {
          "id": 123,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/muamr/",
          "surah_total": 8,
          "moshaf_type": 116,
          "surah_list": "36,93,94,97,101,109,110,111"
        }
      ]
    },
    {
      "id": 113,
      "name": "Majed Al-Zamil",
      "letter": "M",
      "moshaf": [
        {
          "id": 125,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/zaml/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 114,
      "name": "Maher Shakhashero",
      "letter": "M",
      "moshaf": [
        {
          "id": 126,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/shaksh/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 115,
      "name": "Mohammad AlMonshed",
      "letter": "M",
      "moshaf": [
        {
          "id": 127,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/monshed/",
          "surah_total": 110,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,80,81,82,83,84,85,86,87,88,90,91,92,93,94,95,96,97,98,99,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 116,
      "name": "Mahmood AlSheimy",
      "letter": "M",
      "moshaf": [
        {
          "id": 128,
          "name": "Rewayat AlDorai A'n Al-Kisa'ai - Murattal",
          "server": "https://server10.mp3quran.net/sheimy/",
          "surah_total": 114,
          "moshaf_type": 1216,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 117,
      "name": "Yasser Salamah",
      "letter": "Y",
      "moshaf": [
        {
          "id": 129,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/salamah/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 118,
      "name": "Akhil Abdulhayy Rawa",
      "letter": "A",
      "moshaf": [
        {
          "id": 130,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/malaysia/akil/",
          "surah_total": 4,
          "moshaf_type": 116,
          "surah_list": "50,51,52,56"
        }
      ]
    },
    {
      "id": 119,
      "name": "Ustaz Zamri",
      "letter": "U",
      "moshaf": [
        {
          "id": 131,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/malaysia/zamri/",
          "surah_total": 7,
          "moshaf_type": 116,
          "surah_list": "32,44,55,56,61,67,76"
        }
      ]
    },
    {
      "id": 12,
      "name": "Idrees Abkr",
      "letter": "I",
      "moshaf": [
        {
          "id": 12,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/abkr/",
          "surah_total": 111,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,47,48,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 120,
      "name": "Khalid Almohana",
      "letter": "K",
      "moshaf": [
        {
          "id": 132,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/mohna/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 121,
      "name": "Adel Al-Khalbany",
      "letter": "A",
      "moshaf": [
        {
          "id": 133,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/a_klb/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 122,
      "name": "Mousa Bilal",
      "letter": "M",
      "moshaf": [
        {
          "id": 134,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/bilal/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 123,
      "name": "Hussain Alshaik",
      "letter": "H",
      "moshaf": [
        {
          "id": 135,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/alshaik/",
          "surah_total": 15,
          "moshaf_type": 116,
          "surah_list": "13,14,22,32,38,44,45,49,50,78,79,80,81,82,85"
        }
      ]
    },
    {
      "id": 124,
      "name": "Hatem Fareed Alwaer",
      "letter": "H",
      "moshaf": [
        {
          "id": 136,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/hatem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 125,
      "name": "Ibrahim Aljormy",
      "letter": "I",
      "moshaf": [
        {
          "id": 137,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/jormy/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 126,
      "name": "Mahmood Al rifai",
      "letter": "M",
      "moshaf": [
        {
          "id": 138,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/mrifai/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 127,
      "name": "Nasser Al obaid",
      "letter": "N",
      "moshaf": [
        {
          "id": 139,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/obaid/",
          "surah_total": 9,
          "moshaf_type": 116,
          "surah_list": "7,13,14,15,25,26,27,40,41"
        }
      ]
    },
    {
      "id": 128,
      "name": "Wasel Almethen",
      "letter": "W",
      "moshaf": [
        {
          "id": 140,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/wasel/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 9,
          "moshaf_type": 116,
          "surah_list": "8,9,36,38,42,45,50,59,60"
        }
      ]
    },
    {
      "id": 129,
      "name": "Ibrahim Aldosari",
      "letter": "I",
      "moshaf": [
        {
          "id": 188,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/ibrahim_dosri/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 142,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server10.mp3quran.net/ibrahim_dosri/Rewayat-Warsh-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 216,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 13,
      "name": "Alzain Mohammad Ahmad",
      "letter": "A",
      "moshaf": [
        {
          "id": 13,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/alzain/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 130,
      "name": "Jamaan Alosaimi",
      "letter": "J",
      "moshaf": [
        {
          "id": 144,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/jaman/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 131,
      "name": "Rodziah Abdulrahman",
      "letter": "R",
      "moshaf": [
        {
          "id": 146,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/malaysia/rziah/",
          "surah_total": 4,
          "moshaf_type": 116,
          "surah_list": "3,8,33,35"
        }
      ]
    },
    {
      "id": 132,
      "name": "Rogayah Sulong",
      "letter": "R",
      "moshaf": [
        {
          "id": 147,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/malaysia/rogiah/",
          "surah_total": 1,
          "moshaf_type": 116,
          "surah_list": "36"
        }
      ]
    },
    {
      "id": 133,
      "name": "Sapinah Mamat",
      "letter": "S",
      "moshaf": [
        {
          "id": 148,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/malaysia/mamat/",
          "surah_total": 4,
          "moshaf_type": 116,
          "surah_list": "3,14,21,22"
        }
      ]
    },
    {
      "id": 134,
      "name": "Saidin Abdulrahman",
      "letter": "S",
      "moshaf": [
        {
          "id": 149,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/malaysia/sideen/",
          "surah_total": 4,
          "moshaf_type": 116,
          "surah_list": "17,23,56,75"
        }
      ]
    },
    {
      "id": 135,
      "name": "Abdulghani Abdullah",
      "letter": "A",
      "moshaf": [
        {
          "id": 150,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/malaysia/abdulgani/",
          "surah_total": 13,
          "moshaf_type": 116,
          "surah_list": "1,2,5,6,9,67,87,91,92,94,95,97,114"
        }
      ]
    },
    {
      "id": 136,
      "name": "Abdullah Fahmi",
      "letter": "A",
      "moshaf": [
        {
          "id": 151,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/malaysia/fhmi/",
          "surah_total": 4,
          "moshaf_type": 116,
          "surah_list": "1,36,53,75"
        }
      ]
    },
    {
      "id": 137,
      "name": "Muhammad Al-Hafiz",
      "letter": "M",
      "moshaf": [
        {
          "id": 152,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/malaysia/hafz/",
          "surah_total": 3,
          "moshaf_type": 116,
          "surah_list": "1,19,31"
        }
      ]
    },
    {
      "id": 138,
      "name": "Mohammed Hafas Ali",
      "letter": "M",
      "moshaf": [
        {
          "id": 153,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/malaysia/hfs/",
          "surah_total": 5,
          "moshaf_type": 116,
          "surah_list": "1,9,11,13,67"
        }
      ]
    },
    {
      "id": 139,
      "name": "Muhammed Khairul Anuar",
      "letter": "M",
      "moshaf": [
        {
          "id": 154,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/malaysia/nor/",
          "surah_total": 4,
          "moshaf_type": 116,
          "surah_list": "1,12,19,31"
        }
      ]
    },
    {
      "id": 14,
      "name": "Al-Qaria Yassen",
      "letter": "A",
      "moshaf": [
        {
          "id": 14,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server11.mp3quran.net/qari/",
          "surah_total": 114,
          "moshaf_type": 216,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 140,
      "name": "Yousef Bin Noah Ahmad",
      "letter": "Y",
      "moshaf": [
        {
          "id": 155,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/noah/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 141,
      "name": "Jamal Addeen Alzailaie",
      "letter": "J",
      "moshaf": [
        {
          "id": 156,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/zilaie/",
          "surah_total": 8,
          "moshaf_type": 116,
          "surah_list": "1,19,32,54,67,70,73,91"
        }
      ]
    },
    {
      "id": 142,
      "name": "Moeedh Alharthi",
      "letter": "M",
      "moshaf": [
        {
          "id": 159,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/harthi/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 143,
      "name": "Mohammad Rashad Alshareef",
      "letter": "M",
      "moshaf": [
        {
          "id": 160,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/rashad/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 145,
      "name": "Ahmed Al-trabulsi",
      "letter": "A",
      "moshaf": [
        {
          "id": 215,
          "name": "Rewayat Qalon A'n Nafi' - Murattal",
          "server": "https://server10.mp3quran.net/trablsi/",
          "surah_total": 114,
          "moshaf_type": 516,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 162,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/trabulsi/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 146,
      "name": "Abdullah Al-Kandari",
      "letter": "A",
      "moshaf": [
        {
          "id": 163,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/Abdullahk/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 147,
      "name": "Ahmed Amer",
      "letter": "A",
      "moshaf": [
        {
          "id": 164,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/Aamer/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 148,
      "name": "Ibrahem Assadan",
      "letter": "I",
      "moshaf": [
        {
          "id": 165,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/IbrahemSadan/",
          "surah_total": 4,
          "moshaf_type": 116,
          "surah_list": "1,6,19,20"
        }
      ]
    },
    {
      "id": 149,
      "name": "Ahmad Alhuthaifi",
      "letter": "A",
      "moshaf": [
        {
          "id": 166,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/ahmad_huth/",
          "surah_total": 105,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,35,38,41,42,43,44,45,47,48,49,50,51,52,53,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 15,
      "name": "Alashri Omran",
      "letter": "A",
      "moshaf": [
        {
          "id": 15,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/omran/",
          "surah_total": 113,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 150,
      "name": "Mohammed Osman Khan",
      "letter": "M",
      "moshaf": [
        {
          "id": 167,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/khan/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 151,
      "name": "Youssef Edghouch",
      "letter": "Y",
      "moshaf": [
        {
          "id": 168,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server7.mp3quran.net/dgsh/",
          "surah_total": 22,
          "moshaf_type": 116,
          "surah_list": "1,3,55,67,71,75,82,85,90,91,92,100,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 152,
      "name": "Addokali Mohammad Alalim",
      "letter": "A",
      "moshaf": [
        {
          "id": 169,
          "name": "Rewayat Qalon A'n Nafi' - Murattal",
          "server": "https://server7.mp3quran.net/dokali/",
          "surah_total": 114,
          "moshaf_type": 516,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 153,
      "name": "Wishear Hayder Arbili",
      "letter": "W",
      "moshaf": [
        {
          "id": 170,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/wishear/",
          "surah_total": 2,
          "moshaf_type": 116,
          "surah_list": "55,56"
        }
      ]
    },
    {
      "id": 154,
      "name": "Alfateh Alzubair",
      "letter": "A",
      "moshaf": [
        {
          "id": 172,
          "name": "Rewayat Aldori A'n Abi Amr - Murattal",
          "server": "https://server6.mp3quran.net/fateh/",
          "surah_total": 114,
          "moshaf_type": 1316,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 155,
      "name": "Tareq Abdulgani daawob",
      "letter": "T",
      "moshaf": [
        {
          "id": 173,
          "name": "Rewayat Qalon A'n Nafi' - Murattal",
          "server": "https://server10.mp3quran.net/tareq/",
          "surah_total": 114,
          "moshaf_type": 516,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 156,
      "name": "Othman Al-Ansary",
      "letter": "O",
      "moshaf": [
        {
          "id": 174,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/Othmn/",
          "surah_total": 76,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,40,41,42,43,44,46,47,48,49,50,51,52,53,54,55,56,58,59,60,61,62,63,64,65,66,67,68,69,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 157,
      "name": "Bandar Balilah",
      "letter": "B",
      "moshaf": [
        {
          "id": 175,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/balilah/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 158,
      "name": "Khalid Al-Shoraimy",
      "letter": "K",
      "moshaf": [
        {
          "id": 176,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/shoraimy/",
          "surah_total": 73,
          "moshaf_type": 116,
          "surah_list": "1,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 159,
      "name": "Wadeea Al-Yamani",
      "letter": "W",
      "moshaf": [
        {
          "id": 177,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/wdee3/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 16,
      "name": "Aloyoon Al-Koshi",
      "letter": "A",
      "moshaf": [
        {
          "id": 16,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server11.mp3quran.net/koshi/",
          "surah_total": 114,
          "moshaf_type": 216,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 160,
      "name": "Raad Al Kurdi",
      "letter": "R",
      "moshaf": [
        {
          "id": 178,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/kurdi/",
          "surah_total": 93,
          "moshaf_type": 116,
          "surah_list": "1,2,3,12,13,17,18,19,20,21,22,23,25,26,27,28,29,30,31,32,33,35,36,37,38,39,41,42,43,44,46,47,48,51,52,56,57,58,59,60,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 162,
      "name": "Abdulrahman Aloosi",
      "letter": "A",
      "moshaf": [
        {
          "id": 181,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/aloosi/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 163,
      "name": "Khalid Algamdi",
      "letter": "K",
      "moshaf": [
        {
          "id": 182,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/ghamdi/",
          "surah_total": 28,
          "moshaf_type": 116,
          "surah_list": "1,6,9,14,21,25,30,42,50,52,53,54,58,59,60,61,65,67,68,69,70,71,77,85,86,88,91,93"
        }
      ]
    },
    {
      "id": 164,
      "name": "Ramadan Shakoor",
      "letter": "R",
      "moshaf": [
        {
          "id": 183,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/shakoor/",
          "surah_total": 61,
          "moshaf_type": 116,
          "surah_list": "1,3,10,13,14,23,26,29,35,36,39,40,42,43,47,48,49,50,51,57,58,59,60,63,68,69,70,71,72,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,111,112,113,114"
        }
      ]
    },
    {
      "id": 165,
      "name": "Abdulmajeed Al-Arkani",
      "letter": "A",
      "moshaf": [
        {
          "id": 184,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server7.mp3quran.net/m_arkani/",
          "surah_total": 46,
          "moshaf_type": 116,
          "surah_list": "12,18,19,21,22,40,50,56,76,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 166,
      "name": "Mohammad Khalil Al-Qari",
      "letter": "M",
      "moshaf": [
        {
          "id": 185,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/m_qari/",
          "surah_total": 15,
          "moshaf_type": 116,
          "surah_list": "1,13,14,19,22,32,42,52,53,54,67,68,69,70,71"
        }
      ]
    },
    {
      "id": 167,
      "name": "Rami Aldeais",
      "letter": "R",
      "moshaf": [
        {
          "id": 186,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/rami/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 168,
      "name": "Hazza Al-Balushi",
      "letter": "H",
      "moshaf": [
        {
          "id": 187,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/hazza/",
          "surah_total": 75,
          "moshaf_type": 116,
          "surah_list": "1,13,14,15,18,19,25,29,30,31,36,37,38,39,40,42,44,47,49,50,51,52,53,54,55,56,57,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 169,
      "name": "Abdulrahman Al-Majed",
      "letter": "A",
      "moshaf": [
        {
          "id": 189,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/a_majed/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 17,
      "name": "Tawfeeq As-Sayegh",
      "letter": "T",
      "moshaf": [
        {
          "id": 17,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/twfeeq/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 170,
      "name": "Salman Alotaibi",
      "letter": "S",
      "moshaf": [
        {
          "id": 190,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/salman/",
          "surah_total": 61,
          "moshaf_type": 116,
          "surah_list": "1,2,36,46,56,58,59,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 171,
      "name": "Mohammad Refat",
      "letter": "M",
      "moshaf": [
        {
          "id": 191,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server14.mp3quran.net/refat/",
          "surah_total": 32,
          "moshaf_type": 116,
          "surah_list": "1,2,10,11,12,17,18,19,20,48,54,55,56,69,72,73,75,76,77,78,79,81,82,83,85,86,87,88,89,96,98,100"
        }
      ]
    },
    {
      "id": 172,
      "name": "Abdullah Al-Mousa",
      "letter": "A",
      "moshaf": [
        {
          "id": 270,
          "name": "Almusshaf Al Mo'lim - Almusshaf Al Mo'lim",
          "server": "https://server14.mp3quran.net/mousa/Almusshaf-Al-Mo-lim/",
          "surah_total": 38,
          "moshaf_type": 418,
          "surah_list": "1,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 192,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server14.mp3quran.net/mousa/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 102,
          "moshaf_type": 116,
          "surah_list": "1,2,3,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,25,26,27,29,31,32,33,35,36,37,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 173,
      "name": "Abdullah Al-Khalaf",
      "letter": "A",
      "moshaf": [
        {
          "id": 193,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server14.mp3quran.net/khalf/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 174,
      "name": "Mansour Al-Salemi",
      "letter": "M",
      "moshaf": [
        {
          "id": 194,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server14.mp3quran.net/mansor/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 175,
      "name": "Salah Musali",
      "letter": "S",
      "moshaf": [
        {
          "id": 195,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server14.mp3quran.net/musali/",
          "surah_total": 48,
          "moshaf_type": 116,
          "surah_list": "67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 176,
      "name": "Khalid Alsharekh",
      "letter": "K",
      "moshaf": [
        {
          "id": 196,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server14.mp3quran.net/sharekh/",
          "surah_total": 64,
          "moshaf_type": 116,
          "surah_list": "2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 177,
      "name": "Nasser Alosfor",
      "letter": "N",
      "moshaf": [
        {
          "id": 197,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server14.mp3quran.net/alosfor/",
          "surah_total": 111,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 18,
      "name": "Jamal Shaker Abdullah",
      "letter": "J",
      "moshaf": [
        {
          "id": 18,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/jamal/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 181,
      "name": "Ahmed Al-Swailem",
      "letter": "A",
      "moshaf": [
        {
          "id": 201,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server14.mp3quran.net/swlim/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 182,
      "name": "Islam Sobhi",
      "letter": "I",
      "moshaf": [
        {
          "id": 202,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server14.mp3quran.net/islam/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 24,
          "moshaf_type": 116,
          "surah_list": "13,17,18,26,31,32,41,42,44,50,53,54,55,56,59,64,66,67,70,72,76,79,85,88"
        }
      ]
    },
    {
      "id": 184,
      "name": "Bader Alturki",
      "letter": "B",
      "moshaf": [
        {
          "id": 204,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/bader/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 186,
      "name": "Hitham Aljadani",
      "letter": "H",
      "moshaf": [
        {
          "id": 206,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/hitham/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 38,
          "moshaf_type": 116,
          "surah_list": "1,2,6,7,8,9,10,12,13,14,15,16,28,29,30,31,32,34,35,36,37,38,44,50,51,52,53,54,55,56,57,69,75,76,85,87,88,90"
        }
      ]
    },
    {
      "id": 187,
      "name": "Nasser Almajed",
      "letter": "N",
      "moshaf": [
        {
          "id": 207,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server14.mp3quran.net/nasser_almajed/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 188,
      "name": "Ahmad Shaheen",
      "letter": "A",
      "moshaf": [
        {
          "id": 208,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/shaheen/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 189,
      "name": "Mohammad Albukheet",
      "letter": "M",
      "moshaf": [
        {
          "id": 209,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server14.mp3quran.net/bukheet/",
          "surah_total": 109,
          "moshaf_type": 116,
          "surah_list": "1,2,3,7,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 19,
      "name": "Hamad Al Daghriri",
      "letter": "H",
      "moshaf": [
        {
          "id": 19,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/hamad/",
          "surah_total": 5,
          "moshaf_type": 116,
          "surah_list": "1,12,13,43,44"
        }
      ]
    },
    {
      "id": 190,
      "name": "Saad Almqren",
      "letter": "S",
      "moshaf": [
        {
          "id": 210,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/saad/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 191,
      "name": "Ahmad Al Nufais",
      "letter": "A",
      "moshaf": [
        {
          "id": 211,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/nufais/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 192,
      "name": "Omar Al Darweez",
      "letter": "O",
      "moshaf": [
        {
          "id": 212,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/darweez/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 61,
          "moshaf_type": 116,
          "surah_list": "1,12,13,15,18,19,25,32,36,38,44,47,48,50,51,52,53,54,56,61,62,63,64,66,67,68,70,71,72,73,74,75,76,78,79,80,82,85,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 197,
      "name": "Abdulaziz Alasiri",
      "letter": "A",
      "moshaf": [
        {
          "id": 213,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/abdulazizasiri/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 21,
          "moshaf_type": 116,
          "surah_list": "2,3,5,11,12,13,14,17,18,36,45,51,55,57,67,70,71,73,78,86,88"
        }
      ]
    },
    {
      "id": 198,
      "name": "Younes Souilass",
      "letter": "Y",
      "moshaf": [
        {
          "id": 214,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server16.mp3quran.net/souilass/Rewayat-Warsh-A-n-Nafi/",
          "surah_total": 13,
          "moshaf_type": 216,
          "surah_list": "1,18,19,25,50,51,56,57,67,73,91,97,112"
        }
      ]
    },
    {
      "id": 199,
      "name": "Ahmad Deban",
      "letter": "A",
      "moshaf": [
        {
          "id": 298,
          "name": "Ibn Jammaz A'n Abi Ja'far - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Ibn-Jammaz-A-n-Abi-Ja-far/",
          "surah_total": 11,
          "moshaf_type": 2016,
          "surah_list": "1,93,100,102,103,109,110,111,112,113,114"
        },
        {
          "id": 297,
          "name": "Hesham A'n Abi A'mer - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Hesham-A-n-Abi-A-mer/",
          "surah_total": 27,
          "moshaf_type": 1916,
          "surah_list": "1,2,3,4,5,6,7,8,9,13,85,90,92,93,94,95,99,100,101,103,105,107,108,110,112,113,114"
        },
        {
          "id": 296,
          "name": "Rewayat Khalaf A'n Hamzah - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Khalaf-A-n-Hamzah/",
          "surah_total": 6,
          "moshaf_type": 316,
          "surah_list": "94,97,101,107,108,109"
        },
        {
          "id": 295,
          "name": "Rewayat AlDorai A'n Al-Kisa'ai - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-AlDorai-A-n-Al-Kisa-ai/",
          "surah_total": 13,
          "moshaf_type": 1216,
          "surah_list": "1,94,95,100,103,105,106,108,109,110,112,113,114"
        },
        {
          "id": 294,
          "name": "Rewayat Assosi A'n Abi Amr - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Assosi-A-n-Abi-Amr/",
          "surah_total": 19,
          "moshaf_type": 716,
          "surah_list": "1,82,86,87,88,93,94,95,99,102,103,106,108,109,110,111,112,113,114"
        },
        {
          "id": 293,
          "name": "Ibn Thakwan A'n Ibn Amer - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Ibn-Thakwan-A-n-Ibn-Amer/",
          "surah_total": 33,
          "moshaf_type": 1516,
          "surah_list": "1,2,3,4,5,6,7,8,9,13,77,83,85,86,88,90,92,93,94,95,96,97,99,100,101,103,105,107,108,109,112,113,114"
        },
        {
          "id": 285,
          "name": "Rewayat Warsh A'n Nafi' Men Tariq Alazraq - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Warsh-A-n-Nafi-Men-Tariq-Alazraq/",
          "surah_total": 114,
          "moshaf_type": 1816,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 264,
          "name": "Rewayat Aldori A'n Abi Amr - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Aldori-A-n-Abi-Amr/",
          "surah_total": 114,
          "moshaf_type": 1316,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 259,
          "name": "Rewayat Qalon A'n Nafi' - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Qalon-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 516,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 258,
          "name": "Rewayat Albizi A'n Ibn Katheer - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Albizi-A-n-Ibn-Katheer/",
          "surah_total": 114,
          "moshaf_type": 1616,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 257,
          "name": "Rewayat Qunbol A'n Ibn Katheer - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Qunbol-A-n-Ibn-Katheer/",
          "surah_total": 114,
          "moshaf_type": 1716,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 243,
          "name": "Sho'bah A'n Asim - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Sho-bah-A-n-Asim/",
          "surah_total": 114,
          "moshaf_type": 1416,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 216,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/deban/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 2,
      "name": "Ibrahim Al-Jebreen",
      "letter": "I",
      "moshaf": [
        {
          "id": 2,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/jbreen/",
          "surah_total": 99,
          "moshaf_type": 116,
          "surah_list": "1,3,4,7,8,10,12,13,14,15,16,17,18,19,20,21,23,25,27,31,32,33,34,36,37,38,39,40,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 20,
      "name": "Khalid Al-Jileel",
      "letter": "K",
      "moshaf": [
        {
          "id": 20,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/jleel/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 200,
      "name": "Abdullah Kamel",
      "letter": "A",
      "moshaf": [
        {
          "id": 220,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/kamel/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 201,
      "name": "Peshawa Qadr Al-Kurdi",
      "letter": "P",
      "moshaf": [
        {
          "id": 222,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/peshawa/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 204,
      "name": "Nathier Almalki",
      "letter": "N",
      "moshaf": [
        {
          "id": 226,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net//nathier/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 206,
      "name": "Okasha Kameny",
      "letter": "O",
      "moshaf": [
        {
          "id": 280,
          "name": "Rewayat Albizi A'n Ibn Katheer - Murattal",
          "server": "https://server16.mp3quran.net/okasha/Rewayat-Albizi-A-n-Ibn-Katheer/",
          "surah_total": 3,
          "moshaf_type": 1616,
          "surah_list": "2,3,4"
        },
        {
          "id": 244,
          "name": "Rewayat AlDorai A'n Al-Kisa'ai - Murattal",
          "server": "https://server16.mp3quran.net/okasha/Rewayat-AlDorai-A-n-Al-Kisa-ai/",
          "surah_total": 38,
          "moshaf_type": 1216,
          "surah_list": "17,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 208,
      "name": "Haitham Aldukhain",
      "letter": "H",
      "moshaf": [
        {
          "id": 246,
          "name": "Rewayat Hafs A'n Assem - 19",
          "server": "https://server16.mp3quran.net/h_dukhain/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 119,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 21,
      "name": "Khaled Al-Qahtani",
      "letter": "K",
      "moshaf": [
        {
          "id": 21,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/qht/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 22,
      "name": "Khalid Abdulkafi",
      "letter": "K",
      "moshaf": [
        {
          "id": 22,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/kafi/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 221,
      "name": "Muhammad Abu Sneina",
      "letter": "M",
      "moshaf": [
        {
          "id": 247,
          "name": "Rewayat Qalon A'n Nafi' - Murattal",
          "server": "https://server16.mp3quran.net/sneineh/Rewayat-Qalon-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 516,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 222,
      "name": "Mohammed Al-Amin Qeniwa",
      "letter": "M",
      "moshaf": [
        {
          "id": 248,
          "name": "Rewayat Qalon A'n Nafi' - Murattal",
          "server": "https://server16.mp3quran.net/qeniwa/Rewayat-Qalon-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 516,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 223,
      "name": "Mahmoud Abdul Hakam",
      "letter": "M",
      "moshaf": [
        {
          "id": 250,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/m_abdelhakam/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 113,
          "moshaf_type": 116,
          "surah_list": "1,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 227,
      "name": "Mohammad Saayed",
      "letter": "M",
      "moshaf": [
        {
          "id": 251,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server16.mp3quran.net/m_sayed/Rewayat-Warsh-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 216,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 228,
      "name": "Abdulrahman Alsuwayid",
      "letter": "A",
      "moshaf": [
        {
          "id": 252,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a_swaiyd/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 85,
          "moshaf_type": 116,
          "surah_list": "2,11,15,18,19,25,34,35,36,37,38,39,40,41,42,43,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 229,
      "name": "Abdulelah bin Aoun",
      "letter": "A",
      "moshaf": [
        {
          "id": 253,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a_binaoun/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 23,
      "name": "Khalid Al-Wehabi",
      "letter": "K",
      "moshaf": [
        {
          "id": 23,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/whabi/",
          "surah_total": 11,
          "moshaf_type": 116,
          "surah_list": "12,13,14,16,19,24,25,29,30,31,32"
        }
      ]
    },
    {
      "id": 230,
      "name": "Ahmad Talib bin Humaid",
      "letter": "A",
      "moshaf": [
        {
          "id": 254,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a_binhameed/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 82,
          "moshaf_type": 116,
          "surah_list": "1,2,5,6,8,10,12,18,20,29,30,31,32,35,37,38,44,45,49,50,51,52,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 231,
      "name": "Noreen Mohammad Siddiq",
      "letter": "N",
      "moshaf": [
        {
          "id": 255,
          "name": "Rewayat Aldori A'n Abi Amr - Murattal",
          "server": "https://server16.mp3quran.net/nourin_siddig/Rewayat-Aldori-A-n-Abi-Amr/",
          "surah_total": 114,
          "moshaf_type": 1316,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 232,
      "name": "Abdullah Bukhari",
      "letter": "A",
      "moshaf": [
        {
          "id": 256,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a_bukhari/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 80,
          "moshaf_type": 116,
          "surah_list": "1,2,36,37,38,39,40,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 233,
      "name": "Abdulaziz Alturki",
      "letter": "A",
      "moshaf": [
        {
          "id": 260,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a_turki/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 66,
          "moshaf_type": 116,
          "surah_list": "1,2,6,7,8,9,10,11,12,13,18,21,24,25,26,27,28,29,30,31,39,40,41,44,46,48,49,54,69,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 234,
      "name": "Mukhtar Al-Haj",
      "letter": "M",
      "moshaf": [
        {
          "id": 261,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/mukhtar_haj/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 16,
          "moshaf_type": 116,
          "surah_list": "1,2,8,12,14,16,18,35,36,41,42,47,55,56,67,68"
        }
      ]
    },
    {
      "id": 235,
      "name": "Abdullah Abdal",
      "letter": "A",
      "moshaf": [
        {
          "id": 263,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a_abdl/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 236,
      "name": "Marwan Alakri",
      "letter": "M",
      "moshaf": [
        {
          "id": 271,
          "name": "Rewayat Qalon A'n Nafi' - Murattal",
          "server": "https://server16.mp3quran.net/m_akri/Rewayat-Qalon-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 516,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 237,
      "name": "Ahmad Issa Al Maasaraawi",
      "letter": "A",
      "moshaf": [
        {
          "id": 274,
          "name": "Rewayat Rowis and Rawh A'n Yakoob Al Hadrami  - Murattal",
          "server": "https://server16.mp3quran.net/a_maasaraawi/Rewayat-Rawh-A-n-Yakoob-Alhadrami/",
          "surah_total": 64,
          "moshaf_type": 916,
          "surah_list": "51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 273,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a_maasaraawi/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 238,
      "name": "Ibrahim Kshidan",
      "letter": "I",
      "moshaf": [
        {
          "id": 275,
          "name": "Rewayat Qalon A'n Nafi' - Murattal",
          "server": "https://server16.mp3quran.net/i_kshidan/Rewayat-Qalon-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 516,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 239,
      "name": "Hashim Abu Dalal",
      "letter": "H",
      "moshaf": [
        {
          "id": 276,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/h_abudalal/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 24,
      "name": "Khalifa Altunaiji",
      "letter": "K",
      "moshaf": [
        {
          "id": 24,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/tnjy/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 240,
      "name": "Fouad Alkhamery",
      "letter": "F",
      "moshaf": [
        {
          "id": 277,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/f_khamery/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 241,
      "name": "Sayed Ahmad Hashemi",
      "letter": "S",
      "moshaf": [
        {
          "id": 278,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/s_hashemi/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 242,
      "name": "Khalid Mohammadi",
      "letter": "K",
      "moshaf": [
        {
          "id": 279,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/kh_mohammadi/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 243,
      "name": "Saleh Alshamrani",
      "letter": "S",
      "moshaf": [
        {
          "id": 281,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/shamrani/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 244,
      "name": "Mal-Allah Abdulrhman Aljaber",
      "letter": "M",
      "moshaf": [
        {
          "id": 282,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/mal-allah_jaber/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 245,
      "name": "Salman Alsadeiq",
      "letter": "S",
      "moshaf": [
        {
          "id": 283,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/s_sadeiq/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 246,
      "name": "Hasan Saleh",
      "letter": "H",
      "moshaf": [
        {
          "id": 284,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/h_saleh/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 247,
      "name": "Abdulrahman Alshahhat",
      "letter": "A",
      "moshaf": [
        {
          "id": 286,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a_alshahhat/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 248,
      "name": "Issa Omar Sanankoua",
      "letter": "I",
      "moshaf": [
        {
          "id": 287,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/i_sanankoua/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 26,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,6,12,14,18,20,32,35,40,50,56,57,58,63,67,71,78,87,88,91,112,113,114"
        }
      ]
    },
    {
      "id": 249,
      "name": "Haroon Baqai",
      "letter": "H",
      "moshaf": [
        {
          "id": 290,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/h_baqai/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 51,
          "moshaf_type": 116,
          "surah_list": "1,56,62,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 25,
      "name": "Dawood Hamza",
      "letter": "D",
      "moshaf": [
        {
          "id": 25,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/hamza/",
          "surah_total": 86,
          "moshaf_type": 116,
          "surah_list": "2,3,5,6,7,8,10,12,14,16,17,18,19,20,21,23,24,25,27,28,29,31,33,34,35,36,37,38,40,41,42,46,47,48,50,52,53,54,56,58,60,61,63,65,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,92,93,96,97,98,101,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 250,
      "name": "Saleh Alquraishi",
      "letter": "S",
      "moshaf": [
        {
          "id": 291,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/s_alquraishi/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 251,
      "name": "Faisal Al-Hajry",
      "letter": "F",
      "moshaf": [
        {
          "id": 292,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/f_hajry/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 252,
      "name": "Anas Alemadi",
      "letter": "A",
      "moshaf": [
        {
          "id": 299,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a_alemadi/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 253,
      "name": "Abdulmalik Alaskar",
      "letter": "A",
      "moshaf": [
        {
          "id": 300,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a_alaskar/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 6,
          "moshaf_type": 116,
          "surah_list": "36,40,69,70,71,75"
        }
      ]
    },
    {
      "id": 26,
      "name": "Rasheed Ifrad",
      "letter": "R",
      "moshaf": [
        {
          "id": 26,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server12.mp3quran.net/ifrad/",
          "surah_total": 15,
          "moshaf_type": 216,
          "surah_list": "1,18,25,26,27,28,29,31,33,35,37,38,41,42,71"
        }
      ]
    },
    {
      "id": 27,
      "name": "Rachid Belalya",
      "letter": "R",
      "moshaf": [
        {
          "id": 217,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/bl3/",
          "surah_total": 5,
          "moshaf_type": 116,
          "surah_list": "46,47,48,49,50"
        },
        {
          "id": 27,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server6.mp3quran.net/bl3/Rewayat-Warsh-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 216,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 28,
      "name": "Zakaria Hamamah",
      "letter": "Z",
      "moshaf": [
        {
          "id": 28,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/zakariya/",
          "surah_total": 7,
          "moshaf_type": 116,
          "surah_list": "32,36,44,56,67,76,85"
        }
      ]
    },
    {
      "id": 285,
      "name": "Abdulkareem Alhazmi",
      "letter": "A",
      "moshaf": [
        {
          "id": 313,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a_alhazmi/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 286,
      "name": "Hicham Lharraz",
      "letter": "H",
      "moshaf": [
        {
          "id": 312,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server16.mp3quran.net/H-Lharraz/Rewayat-Warsh-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 216,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 287,
      "name": "Abdullah Al-Mishal",
      "letter": "A",
      "moshaf": [
        {
          "id": 314,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/a-almishal/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 5,
          "moshaf_type": 116,
          "surah_list": "14,49,49,51,51"
        }
      ]
    },
    {
      "id": 29,
      "name": "Saad Al-Ghamdi",
      "letter": "S",
      "moshaf": [
        {
          "id": 29,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server7.mp3quran.net/s_gmd/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 3,
      "name": "Ibrahim Al-Asiri",
      "letter": "I",
      "moshaf": [
        {
          "id": 3,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/3siri/",
          "surah_total": 27,
          "moshaf_type": 116,
          "surah_list": "1,3,4,5,8,12,13,14,17,18,19,20,21,23,27,34,36,38,51,53,54,56,67,69,70,75,76"
        }
      ]
    },
    {
      "id": 30,
      "name": "Saud Al-Shuraim",
      "letter": "S",
      "moshaf": [
        {
          "id": 30,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server7.mp3quran.net/shur/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 301,
      "name": "Abdelaziz sheim",
      "letter": "A",
      "moshaf": [
        {
          "id": 315,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server16.mp3quran.net/a_sheim/Rewayat-Warsh-A-n-Nafi/",
          "surah_total": 10,
          "moshaf_type": 216,
          "surah_list": "1,18,31,50,56,57,67,72,75,112"
        }
      ]
    },
    {
      "id": 31,
      "name": "Sahl Yassin",
      "letter": "S",
      "moshaf": [
        {
          "id": 31,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/shl/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 32,
      "name": "Zaki Daghistani",
      "letter": "Z",
      "moshaf": [
        {
          "id": 32,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/zaki/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 33,
      "name": "Sami Al-Hasn",
      "letter": "S",
      "moshaf": [
        {
          "id": 33,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/sami_hsn/",
          "surah_total": 27,
          "moshaf_type": 116,
          "surah_list": "1,19,20,24,26,27,32,34,55,81,82,86,87,88,91,92,93,94,95,97,102,104,105,109,110,111,112"
        }
      ]
    },
    {
      "id": 34,
      "name": "Sami Al-Dosari",
      "letter": "S",
      "moshaf": [
        {
          "id": 34,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/sami_dosr/",
          "surah_total": 41,
          "moshaf_type": 116,
          "surah_list": "29,30,31,32,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 35,
      "name": "Sayeed Ramadan",
      "letter": "S",
      "moshaf": [
        {
          "id": 35,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/sayed/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 36,
      "name": "Shaban Al-Sayiaad",
      "letter": "S",
      "moshaf": [
        {
          "id": 36,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/shaban/",
          "surah_total": 20,
          "moshaf_type": 116,
          "surah_list": "3,35,87,88,89,90,91,92,94,95,96,97,98,99,100,101,102,103,104,105"
        }
      ]
    },
    {
      "id": 37,
      "name": "Shirazad Taher",
      "letter": "S",
      "moshaf": [
        {
          "id": 37,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/taher/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 38,
      "name": "Saber Abdulhakm",
      "letter": "S",
      "moshaf": [
        {
          "id": 38,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/hkm/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 39,
      "name": "Saleh Alsahood",
      "letter": "S",
      "moshaf": [
        {
          "id": 39,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/sahood/",
          "surah_total": 110,
          "moshaf_type": 116,
          "surah_list": "1,3,4,5,6,7,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 4,
      "name": "Shaik Abu Bakr Al Shatri",
      "letter": "S",
      "moshaf": [
        {
          "id": 4,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/shatri/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 40,
      "name": "Saleh Al-Talib",
      "letter": "S",
      "moshaf": [
        {
          "id": 40,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/tlb/",
          "surah_total": 32,
          "moshaf_type": 116,
          "surah_list": "1,25,34,38,39,44,45,46,47,55,56,57,58,59,60,61,70,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86"
        }
      ]
    },
    {
      "id": 41,
      "name": "Saleh Al-Habdan",
      "letter": "S",
      "moshaf": [
        {
          "id": 41,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/habdan/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 42,
      "name": "Salah Albudair",
      "letter": "S",
      "moshaf": [
        {
          "id": 42,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/s_bud/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 43,
      "name": "Salah Alhashim",
      "letter": "S",
      "moshaf": [
        {
          "id": 44,
          "name": "Rewayat Qalon A'n Nafi' - Murattal",
          "server": "https://server12.mp3quran.net/salah_hashim_m/Rewayat-Qalon-A-n-Nafi/",
          "surah_total": 7,
          "moshaf_type": 516,
          "surah_list": "1,12,40,50,53,75,76"
        },
        {
          "id": 43,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/salah_hashim_m/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 44,
      "name": "Slaah Bukhatir",
      "letter": "S",
      "moshaf": [
        {
          "id": 45,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/bu_khtr/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 45,
      "name": "Adel Ryyan",
      "letter": "A",
      "moshaf": [
        {
          "id": 46,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/ryan/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 46,
      "name": "Abdelbari Al-Toubayti",
      "letter": "A",
      "moshaf": [
        {
          "id": 47,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/thubti/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 47,
      "name": "Abdulbari Mohammad",
      "letter": "A",
      "moshaf": [
        {
          "id": 141,
          "name": "Almusshaf Al Mo'lim - Almusshaf Al Mo'lim",
          "server": "https://server12.mp3quran.net/bari/Almusshaf-Al-Mo-lim/",
          "surah_total": 91,
          "moshaf_type": 418,
          "surah_list": "1,3,5,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 48,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/bari/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 48,
      "name": "Abdulbasit Abdulsamad",
      "letter": "A",
      "moshaf": [
        {
          "id": 51,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server7.mp3quran.net/basit/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 50,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server7.mp3quran.net/basit/Rewayat-Warsh-A-n-Nafi/",
          "surah_total": 114,
          "moshaf_type": 216,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 49,
          "name": "Almusshaf Al Mojawwad - Almusshaf Al Mojawwad",
          "server": "https://server7.mp3quran.net/basit/Almusshaf-Al-Mojawwad/",
          "surah_total": 114,
          "moshaf_type": 617,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 49,
      "name": "Abdulrahman Alsudaes",
      "letter": "A",
      "moshaf": [
        {
          "id": 52,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/sds/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 5,
      "name": "Ahmad Al-Ajmy",
      "letter": "A",
      "moshaf": [
        {
          "id": 5,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server10.mp3quran.net/ajm/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 50,
      "name": "Abdul Aziz Al-Ahmad",
      "letter": "A",
      "moshaf": [
        {
          "id": 53,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/a_ahmed/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 51,
      "name": "Abdulaziz Az-Zahrani",
      "letter": "A",
      "moshaf": [
        {
          "id": 54,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/zahrani/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 52,
      "name": "Abdullah Al-Burimi",
      "letter": "A",
      "moshaf": [
        {
          "id": 55,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/brmi/",
          "surah_total": 41,
          "moshaf_type": 116,
          "surah_list": "1,49,50,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95"
        }
      ]
    },
    {
      "id": 53,
      "name": "Abdullah Albuajan",
      "letter": "A",
      "moshaf": [
        {
          "id": 56,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/buajan/",
          "surah_total": 83,
          "moshaf_type": 116,
          "surah_list": "1,8,10,11,12,13,14,15,17,18,19,20,21,22,23,24,25,26,28,29,30,32,33,34,35,39,40,41,42,43,48,49,50,55,56,57,60,62,63,64,65,66,67,68,69,73,75,76,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,97,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 54,
      "name": "Abdullah Al-Mattrod",
      "letter": "A",
      "moshaf": [
        {
          "id": 57,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/mtrod/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 55,
      "name": "Abdullah Basfer",
      "letter": "A",
      "moshaf": [
        {
          "id": 58,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/bsfr/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 56,
      "name": "Abdullah Khayyat",
      "letter": "A",
      "moshaf": [
        {
          "id": 59,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/kyat/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 57,
      "name": "Abdullah Al-Johany",
      "letter": "A",
      "moshaf": [
        {
          "id": 60,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server13.mp3quran.net/jhn/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 58,
      "name": "Abdullah Qaulan",
      "letter": "A",
      "moshaf": [
        {
          "id": 61,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/gulan/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 59,
      "name": "Abdulrasheed Soufi",
      "letter": "A",
      "moshaf": [
        {
          "id": 219,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server16.mp3quran.net/soufi/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 63,
          "name": "Rewayat Assosi A'n Abi Amr - Murattal",
          "server": "https://server16.mp3quran.net/soufi/Rewayat-Assosi-A-n-Abi-Amr/",
          "surah_total": 114,
          "moshaf_type": 716,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 62,
          "name": "Rewayat Khalaf A'n Hamzah - Murattal",
          "server": "https://server16.mp3quran.net/soufi/Rewayat-Khalaf-A-n-Hamzah/",
          "surah_total": 114,
          "moshaf_type": 316,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 6,
      "name": "Ahmad Al-Hawashi",
      "letter": "A",
      "moshaf": [
        {
          "id": 6,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/hawashi/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 60,
      "name": "Abdulmohsin Al-Harthy",
      "letter": "A",
      "moshaf": [
        {
          "id": 64,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/mohsin_harthi/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 61,
      "name": "Abdulmohsen Al-Qasim",
      "letter": "A",
      "moshaf": [
        {
          "id": 65,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/qasm/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 62,
      "name": "Abdulmohsin Al-Askar",
      "letter": "A",
      "moshaf": [
        {
          "id": 66,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/askr/",
          "surah_total": 4,
          "moshaf_type": 116,
          "surah_list": "1,2,7,55"
        }
      ]
    },
    {
      "id": 63,
      "name": "Abdulmohsin Al-Obaikan",
      "letter": "A",
      "moshaf": [
        {
          "id": 67,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/obk/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 64,
      "name": "Abdulhadi Kanakeri",
      "letter": "A",
      "moshaf": [
        {
          "id": 68,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/kanakeri/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 65,
      "name": "Abdulwadood Haneef",
      "letter": "A",
      "moshaf": [
        {
          "id": 69,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/wdod/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 66,
      "name": "Abdulwali Al-Arkani",
      "letter": "A",
      "moshaf": [
        {
          "id": 70,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/arkani/",
          "surah_total": 31,
          "moshaf_type": 116,
          "surah_list": "1,2,3,7,12,14,15,16,17,18,19,20,31,36,37,39,40,49,55,56,67,68,69,70,71,72,73,74,75,76,77"
        }
      ]
    },
    {
      "id": 67,
      "name": "Ali Abo-Hashim",
      "letter": "A",
      "moshaf": [
        {
          "id": 71,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/abo_hashim/",
          "surah_total": 8,
          "moshaf_type": 116,
          "surah_list": "50,51,52,53,54,55,56,57"
        }
      ]
    },
    {
      "id": 68,
      "name": "Ali Alhuthaifi",
      "letter": "A",
      "moshaf": [
        {
          "id": 289,
          "name": "Sho'bah A'n Asim - Murattal",
          "server": "https://server9.mp3quran.net/hthfi/Rewayat-Sho-bah-A-n-Asim/",
          "surah_total": 114,
          "moshaf_type": 1416,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 218,
          "name": "Rewayat Qalon A'n Nafi' - Murattal",
          "server": "https://server9.mp3quran.net/huthifi_qalon/",
          "surah_total": 114,
          "moshaf_type": 516,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 72,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/hthfi/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 7,
      "name": "Ahmad Saud",
      "letter": "A",
      "moshaf": [
        {
          "id": 7,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/saud/",
          "surah_total": 30,
          "moshaf_type": 116,
          "surah_list": "85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 70,
      "name": "Ali Jaber",
      "letter": "A",
      "moshaf": [
        {
          "id": 74,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/a_jbr/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 71,
      "name": "Ali Hajjaj Alsouasi",
      "letter": "A",
      "moshaf": [
        {
          "id": 75,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/hajjaj/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 72,
      "name": "Emad Hafez",
      "letter": "E",
      "moshaf": [
        {
          "id": 76,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/hafz/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 73,
      "name": "Omar Al-Qazabri",
      "letter": "O",
      "moshaf": [
        {
          "id": 77,
          "name": "Rewayat Warsh A'n Nafi' - Murattal",
          "server": "https://server9.mp3quran.net/omar_warsh/",
          "surah_total": 114,
          "moshaf_type": 216,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 74,
      "name": "Fares Abbad",
      "letter": "F",
      "moshaf": [
        {
          "id": 78,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/frs_a/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 75,
      "name": "Fahad Al-Otaibi",
      "letter": "F",
      "moshaf": [
        {
          "id": 79,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/fahad_otibi/",
          "surah_total": 8,
          "moshaf_type": 116,
          "surah_list": "50,56,69,72,73,74,75,76"
        }
      ]
    },
    {
      "id": 76,
      "name": "Fahad Al-Kandari",
      "letter": "F",
      "moshaf": [
        {
          "id": 80,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/kndri/",
          "surah_total": 53,
          "moshaf_type": 116,
          "surah_list": "12,36,51,52,53,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 77,
      "name": "Fawaz Alkabi",
      "letter": "F",
      "moshaf": [
        {
          "id": 81,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/fawaz/",
          "surah_total": 44,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,21,22,23,25,27,28,29,30,36,39,40,49,54,55,56,57,58,59,62,63,66,67,73,87,109,112"
        }
      ]
    },
    {
      "id": 78,
      "name": "Lafi Al-Oni",
      "letter": "L",
      "moshaf": [
        {
          "id": 82,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/lafi/",
          "surah_total": 52,
          "moshaf_type": 116,
          "surah_list": "3,12,15,16,19,20,27,28,30,31,32,33,36,37,38,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 79,
      "name": "Nasser Alqatami",
      "letter": "N",
      "moshaf": [
        {
          "id": 83,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/qtm/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 8,
      "name": "Ahmad Saber",
      "letter": "A",
      "moshaf": [
        {
          "id": 8,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/saber/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 80,
      "name": "Nabil Al Rifay",
      "letter": "N",
      "moshaf": [
        {
          "id": 84,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/nabil/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 81,
      "name": "Neamah Al-Hassan",
      "letter": "N",
      "moshaf": [
        {
          "id": 85,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/namh/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 82,
      "name": "Hani Arrifai",
      "letter": "H",
      "moshaf": [
        {
          "id": 86,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/hani/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 83,
      "name": "Walid Al-Dulaimi",
      "letter": "W",
      "moshaf": [
        {
          "id": 87,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/dlami/",
          "surah_total": 51,
          "moshaf_type": 116,
          "surah_list": "1,12,17,31,39,41,45,48,49,50,51,52,53,54,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 84,
      "name": "Waleed Alnaehi",
      "letter": "W",
      "moshaf": [
        {
          "id": 88,
          "name": "Rewayat Qalon A'n Nafi' Men Tariq Abi Nasheet - Murattal",
          "server": "https://server9.mp3quran.net/waleed/",
          "surah_total": 114,
          "moshaf_type": 816,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 85,
      "name": "Yasser Al-Dosari",
      "letter": "Y",
      "moshaf": [
        {
          "id": 89,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/yasser/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 86,
      "name": "Yasser Al-Qurashi",
      "letter": "Y",
      "moshaf": [
        {
          "id": 90,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/qurashi/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 87,
      "name": "Yasser Al-Faylakawi",
      "letter": "Y",
      "moshaf": [
        {
          "id": 91,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server6.mp3quran.net/fyl/",
          "surah_total": 52,
          "moshaf_type": 116,
          "surah_list": "1,2,25,44,56,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,113,114"
        }
      ]
    },
    {
      "id": 88,
      "name": "Yasser Al-Mazroyee",
      "letter": "Y",
      "moshaf": [
        {
          "id": 92,
          "name": "Rewayat Rowis and Rawh A'n Yakoob Al Hadrami  - Murattal",
          "server": "https://server9.mp3quran.net/mzroyee/",
          "surah_total": 114,
          "moshaf_type": 916,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 89,
      "name": "Yahya Hawwa",
      "letter": "Y",
      "moshaf": [
        {
          "id": 93,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/yahya/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 9,
      "name": "Ahmad Nauina",
      "letter": "A",
      "moshaf": [
        {
          "id": 9,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/ahmad_nu/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 90,
      "name": "Yousef Alshoaey",
      "letter": "Y",
      "moshaf": [
        {
          "id": 94,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/yousef/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 91,
      "name": "Majed Al-Enezi",
      "letter": "M",
      "moshaf": [
        {
          "id": 95,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/majd_onazi/",
          "surah_total": 69,
          "moshaf_type": 116,
          "surah_list": "1,13,14,17,18,19,30,31,32,34,43,44,45,49,50,51,53,55,56,59,60,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 92,
      "name": "Malik shaibat Alhamed",
      "letter": "M",
      "moshaf": [
        {
          "id": 96,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server9.mp3quran.net/shaibat/",
          "surah_total": 37,
          "moshaf_type": 116,
          "surah_list": "78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 93,
      "name": "Maher Al Meaqli",
      "letter": "M",
      "moshaf": [
        {
          "id": 249,
          "name": "Almusshaf Al Mojawwad - Almusshaf Al Mojawwad",
          "server": "https://server12.mp3quran.net/maher/Almusshaf-Al-Mojawwad/",
          "surah_total": 114,
          "moshaf_type": 617,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 98,
          "name": "Almusshaf Al Mo'lim - Almusshaf Al Mo'lim",
          "server": "https://server12.mp3quran.net/maher/Almusshaf-Al-Mo-lim/",
          "surah_total": 38,
          "moshaf_type": 418,
          "surah_list": "1,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 97,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/maher/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 94,
      "name": "Mohammad Al-Airawy",
      "letter": "M",
      "moshaf": [
        {
          "id": 99,
          "name": "Rewayat Warsh A'n Nafi' Men Tariq Alazraq - Murattal",
          "server": "https://server6.mp3quran.net/earawi/",
          "surah_total": 112,
          "moshaf_type": 1816,
          "surah_list": "1,2,3,4,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 95,
      "name": "Mohammed Al-Barrak",
      "letter": "M",
      "moshaf": [
        {
          "id": 100,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server13.mp3quran.net/braak/",
          "surah_total": 62,
          "moshaf_type": 116,
          "surah_list": "1,12,36,37,44,45,50,51,52,53,54,55,56,57,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 96,
      "name": "Mohammad Al-Tablaway",
      "letter": "M",
      "moshaf": [
        {
          "id": 101,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server12.mp3quran.net/tblawi/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 97,
      "name": "Mohammed Al-Lohaidan",
      "letter": "M",
      "moshaf": [
        {
          "id": 102,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/lhdan/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 98,
      "name": "Mohammed Al-Muhasny",
      "letter": "M",
      "moshaf": [
        {
          "id": 103,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server11.mp3quran.net/mhsny/",
          "surah_total": 113,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    },
    {
      "id": 99,
      "name": "Mohammed Ayyub",
      "letter": "M",
      "moshaf": [
        {
          "id": 221,
          "name": "Rewayat Hafs A'n Assem - 19",
          "server": "https://server16.mp3quran.net/ayyoub2/Rewayat-Hafs-A-n-Assem/",
          "surah_total": 114,
          "moshaf_type": 119,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        },
        {
          "id": 104,
          "name": "Rewayat Hafs A'n Assem - Murattal",
          "server": "https://server8.mp3quran.net/ayyub/",
          "surah_total": 114,
          "moshaf_type": 116,
          "surah_list": "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114"
        }
      ]
    }
  ];



handleReciters(Reciters)

module.exports = Reciters;